import { useMediaQuery, useTheme } from '@mui/material';
import CountUp from 'react-countup';

export default function Counter({ end }) {
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    function getMarginLeft(): string {
        if (isDownSm) {
            return '-2px';
        } else {
            return '-5px';
        }
    }

    return (
        // Boxと先頭文字の間に若干のスペースが入り、bodyメッセージとインデントがずれるのでmarginで修正
        <span style={{ marginLeft: getMarginLeft() }}>
            <CountUp
                start={0}
                end={end}
                duration={2}
                useEasing={true}
                enableScrollSpy={true}
                scrollSpyOnce={true}
            />
        </span>
    );
};