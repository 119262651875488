import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const BaseBox = styled(Box)({
    position: 'relative',
    margin: '0 auto',
    maxWidth: '1520px', // 1920 - 200 - 200
    '@media screen and (min-width: 1900px)': {
        content: "''",
        bottom: 0,
        width: '100%',
    },
    '@media screen and (min-width: 1201px) and (max-width: 1900px)': {
        margin: "0 auto",
        paddingRight: `${142 * 100 / 1366}vw`,
        paddingLeft: `${142 * 100 / 1366}vw`,
    },
    '@media screen and (min-width: 600px) and (max-width: 1200px)': {
        margin: "0 auto",
        paddingRight: `${40 * 100 / 768}vw`,
        paddingLeft: `${40 * 100 / 768}vw`,
    },
    '@media screen and (max-width: 600px)': {
        paddingRight: `${26 / 390 * 100}%`,
        paddingLeft: `${26 / 390 * 100}%`,
    },
});

export function BaseBoxWhite({ children, containerStyles }) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
                background: '#FCFFFF',
                ...containerStyles, // 外部から渡されたスタイルを適用

            }}
        >
            <Box
                sx={{
                    maxWidth: "1520px",
                    margin: "0 auto",
                    padding: "0px 0px 0px 0px",
                    [theme.breakpoints.down('sm')]: {
                        paddingX: `${26 / 390 * 100}%`,
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        paddingX: `${26 / 390 * 100}%`,
                    },
                    [theme.breakpoints.between('lg', 'xl')]: {
                        paddingX: `${142 * 100 / 1366}vw`,
                    },
                }}
            >
                {children}
            </Box>
        </Box >
    )
};

export const BottomLineBox = styled(BaseBox)({
    position: 'relative',
    paddingRight: '0px',
    paddingLeft: '0px',
    margin: 'auto',
    '@media screen and (min-width: 1900px)': {
        '&::after': {
            content: '""',
            position: 'absolute',
            left: '0',
            bottom: '0',
            width: '100%',
            borderBottom: '1px solid rgba(72, 72, 72, 0.2)',
            pointerEvents: 'none', // 子要素に影響しないようにする
        }
    },
    '@media screen and (min-width: 1200px) and (max-width: 1900px)': {
        '&::after': {
            content: "''",
            position: 'absolute',
            bottom: 0,
            left: `${142 * 100 / 1366}vw`,
            right: `${142 * 100 / 1366}vw`,
            borderBottom: '1px solid rgba(72, 72, 72, 0.2)',
        }
    },
    '@media screen and (min-width: 600px) and (max-width: 1200px)': {
        '&::after': {
            content: "''",
            position: 'absolute',
            bottom: 0,
            left: `${40 * 100 / 768}vw`,
            right: `${40 * 100 / 768}vw`,
            borderBottom: '1px solid rgba(72, 72, 72, 0.2)',
        },
    },
    '@media screen and (max-width: 600px)': {
        '&::after': {
            content: "''",
            position: 'absolute',
            bottom: 0,
            left: `${26 / 390 * 100}%`,
            right: `${26 / 390 * 100}%`,
            borderBottom: '1px solid rgba(72, 72, 72, 0.2)',
        },
    },
});
