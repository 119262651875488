import { Typography, createTheme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { BottomLineBox } from "../util/BaseBox";
import FadeInImage from '../util/FadeInImage';


export default function OurCulture() {
    const theme = createTheme();
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        < BottomLineBox
            sx={{
                paddingTop: '139px',
                paddingBottom: '140px',
                [theme.breakpoints.down('sm')]: {
                    paddingTop: '71.4px',
                    paddingBottom: '70px',
                },
                [theme.breakpoints.between('sm', 'lg')]: {
                    paddingTop: '73px',
                    paddingBottom: '73px',
                },
            }
            }>
            {isMobile ? (
                <Box>
                    <Typography component="h1" variant="h1"
                        sx={{ paddingBottom: "10.7px" }}
                    >
                        {t('ourCulture.title')}
                    </Typography>
                    <Typography component="h2" variant="h2"
                        sx={{ paddingBottom: "11.7px" }}>
                        {t('ourCulture.subtitle')}
                    </Typography>
                    <Typography component="p" variant="body1"
                        sx={{
                            paddingBottom: '19px'
                        }}
                    >
                        {t('ourCulture.bodyFirst')}
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="row-reverse" // 子要素を逆順に配置
                    >
                        <Box
                            sx={{
                                width: '224px',
                            }}
                        >
                            <div style={{
                                padding: "109.22% 0 0 0",
                                position: "relative",
                            }}>
                                <iframe
                                    src="https://player.vimeo.com/video/831101272?background=1"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    style={{
                                        position: "absolute",
                                        top: "0",
                                        left: "",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                    title="toppage2"
                                >
                                </iframe>
                            </div>
                        </Box>
                    </Box>
                    <script src="https://player.vimeo.com/api/player.js">
                    </script>
                    <Typography component="p" variant="body1"
                        sx={{
                            paddingTop: '19px',
                            paddingBottom: '22px'
                        }}
                    >
                        {t('ourCulture.bodySecond')}
                    </Typography>
                    <Box
                        width={263}
                    >
                        <FadeInImage src={"images/top/culture2.png"} alt={"image"} style={{ maxWidth: '100%', maxHeight: '100%', verticalAlign: 'top' }} />
                    </Box >
                </Box >
            ) : (
                <Grid container spacing={{ xs: 6, sm: 23 / 8, lg: 5.5 }}>
                    <Grid item xs={12} sm={12 * 320 / (320 + 340)} lg={6.1789} >
                        <Box textAlign="left">
                            <Typography component="h1" variant="h1"
                                sx={{
                                    paddingBottom: "18px",
                                    [theme.breakpoints.between('sm', 'lg')]: {
                                        paddingBottom: '12px',
                                    },
                                }}
                            >
                                {t('ourCulture.title')}
                            </Typography>
                            <Box
                                sx={{
                                    maxWidth: '655px'
                                }}
                            >
                                <Typography component="h2" variant="h2"
                                    sx={{
                                        paddingBottom: "18px",
                                        [theme.breakpoints.between('sm', 'lg')]: {
                                            paddingBottom: '12px',
                                        },
                                    }}>
                                    {t('ourCulture.subtitle')}
                                </Typography>
                                {/* sm以下だと文章が2つにわかれる。 */}
                                <Typography component="p" variant="body1">
                                    {t('ourCulture.bodyFirst')}
                                    {t('ourCulture.bodySecond')}
                                </Typography>
                            </Box>
                            <Box sx={{
                                paddingTop: '176px',
                                [theme.breakpoints.between('sm', 'lg')]: {
                                    paddingTop: '40px',
                                },
                            }}>
                                <FadeInImage src={"images/top/culture2.png"} alt={"image"} style={{ maxWidth: '100%', maxHeight: '100%', verticalAlign: 'top' }} />
                            </Box >
                        </Box >
                    </Grid >
                    {/* Vimeo */}
                    <Grid item xs={12} sm={12 * 340 / (320 + 340)} lg={5.8211} >
                        <Box sx={{
                            [theme.breakpoints.down('sm')]: {
                                height: '300px',
                                paddingRight: '0px'
                            }
                        }}>
                            <div style={{
                                padding: "109.22% 0 0 0",
                                position: "relative"
                            }}>
                                <iframe
                                    src="https://player.vimeo.com/video/831101272?background=1"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    style={{
                                        position: "absolute",
                                        top: "0",
                                        left: "0",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                    title="toppage2"
                                >
                                </iframe>
                            </div>
                            <script src="https://player.vimeo.com/api/player.js">
                            </script>
                        </Box>
                    </Grid >
                </Grid >
            )
            }
        </BottomLineBox >
    );
}