import Box from '@mui/material/Box';
import Player from '@vimeo/player';
import { useEffect } from 'react';


function HeaderVideo({ vimeoId, startHeaderVideo }) {

    const options = {
        id: vimeoId,
        background: true,
        muted: true,
        // width: 1920,
        // height: 1080,
        // maxWidth: 1920,
        // maxHeight: 1080,
        responsive: true
    };

    useEffect(() => {
        // const iframe = document.querySelector('#vimeo-player');
        const player = new Player('header-video', options);

        // ステートが変更されたときに動画を0秒から再生
        const playFromStart = async () => {
            await player.setCurrentTime(0);
            await player.play();
        };

        playFromStart().catch((error) => {
            console.error('Error while playing the video:', error);
        });
    }, [startHeaderVideo]);

    return (
        <Box>
            <div id="header-video"></div>
            {/* <button onClick={() => setstartHeaderVideo(prevState => prevState + 1)}>ステートを変更</button> */}
        </Box>
    );
}

export default HeaderVideo;
