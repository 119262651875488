import { Box, useMediaQuery, useTheme } from '@mui/material';
import 'animate.css';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import './FadeInHoverClipImage.css';

interface FadeInImageProps {
    src: string;
    alt: string;
    style?: React.CSSProperties; // style プロパティをオプションに変更
}

const FadeInHoverClipImage = ({ src, alt, style }: FadeInImageProps) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.8, // 画像が20%表示されたらトリガーするように設定
    });
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Box>
            {/* refを与えた要素がウインドウ内に現れるとinViewがtrue*/}
            <div ref={ref}>
                {/* sm以下ではアニメーションをオフにする。*/}
                {inView && !isDownSm && (
                    <div
                        className="animate__animated animate__fadeInUp bg-class hover-zoom"
                    >
                        <img
                            src={src}
                            alt={alt}
                            style={style}
                            className="fade-in-image2 clip-image"
                        />
                    </div>
                )}
                {isDownSm && (
                    <img
                        src={src}
                        alt={alt}
                        style={style}
                    />
                )}
            </div>
        </Box>
    );
};

export default FadeInHoverClipImage;
