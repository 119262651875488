import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseBox } from "../component-parts/util/BaseBox";


export default function Policy({ setPage }) {

    const handleLinkClick = (url: string) => {
        window.open(url, '_blank');
    };
    const theme = useTheme()
    const { t, i18n } = useTranslation();

    // DMG MORIのprivacy linkの言語を切り替える。
    const privacyLink = i18n.language === 'en' ? 'https://www.dmgmori.co.jp/en/privacy/' : 'https://www.dmgmori.co.jp/privacy/'

    React.useEffect(() => {
        setPage('policy')
    },);

    return (
        <BaseBox
            sx={{
                paddingTop: "100px",
                paddingBottom: "100px",
                [theme.breakpoints.down('sm')]: {
                    paddingTop: '47.3px',
                }
            }}
        >
            <Typography
                component="h1"
                variant="h1"
                paddingBottom='5px' // yが見切れてしまうので
            >
                {t('policy.title')}
            </Typography>
            <Typography
                component="p"
                variant="body1"
                sx={{
                    paddingTop: "15px",
                    paddingBottom: "20px",
                }}
            >
                {/* 当社は、DMG MORIグループの一員として、お客様及び社内ステークホルダーの皆様の個人情報をDMG森精機株式会社の個人情報保護方針に則って取り扱います。<br /> */}
                {/* DMG森精機株式会社の個人情報保護方針は、下記リンクからご確認いただけます。 */}
                {/* {t('policy.body')} */}
                {t('policy.body', { replace: { '\n': <br /> } })}
            </Typography>
            <Typography
                component="h2"
                variant="body1"
                sx={{
                    fontFamily: 'Inter',
                    cursor: 'pointer'
                }}
                onClick={() => handleLinkClick(privacyLink)}
            >
                {privacyLink}
            </Typography>
        </BaseBox>
    );
}
