import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { BottomLineBox } from "../util/BaseBox";
import { HoverLinkedButton } from '../util/HoverLinkedButton';


export default function Career() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    return (
        < BottomLineBox
            sx={{
                paddingTop: '140px',
                paddingBottom: '191px',
                [theme.breakpoints.down('sm')]: {
                    paddingTop: '64.3px',
                    paddingBottom: '110px', // Readmore Buttom分も含める
                },
                [theme.breakpoints.between('sm', 'lg')]: {
                    paddingTop: '73px',
                    paddingBottom: '124px',
                },
            }}>
            <Box sx={{
                paddingBottom: '50.5px',
                [theme.breakpoints.down('sm')]: {
                    paddingBottom: '21px',
                },
                [theme.breakpoints.between('sm', 'lg')]: {
                    paddingBottom: '24px',
                },
            }}>
                <Typography component="h1" variant="h1">
                    {t('career.title')}
                </Typography>
            </Box>
            <Container
                sx={{
                    textAlign: 'center',
                    [theme.breakpoints.down('sm')]: {
                        padding: '0px 0px 0px 0px',
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        padding: '0px 0px 0px 0px',
                    },
                }}
            >
                {/* 画像 + 文字列 */}
                <Box sx={{
                    position: 'relative',
                }}>
                    <div style={{
                        position: 'relative',
                        display: 'inline-block'
                    }}>
                        <img
                            src="images/top/career.png"
                            alt="image"
                            style={{
                                width: '100%',
                                height: 'auto',
                                verticalAlign: 'top',
                            }} />
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                fontSize: 'clamp(10px, 4.5vw, 75px)',
                                // whiteSpace: 'nowrap',
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontStyle: 'normal',
                                    fontVariant: 'normal',
                                    fontWeight: 'normal',
                                    fontSize: '5rem',
                                    fontFamily: 'Inter',
                                    letterSpacing: '-5.4px',
                                    textShadow: '0px 6px 6px #00000071',
                                    color: "#FAFFFF",
                                    opacity: 1,
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: `calc(25 / 390 * 100vw)`,
                                        letterSpacing: `-1.5px`,
                                        lineHeight: '0.72',
                                        textShadow: '0px 1px 1px #00000071',
                                    },
                                    [theme.breakpoints.between('sm', 'lg')]: {
                                        fontSize: `calc(47 / 768 * 100vw)`,
                                        letterSpacing: `-1.8px`,
                                        lineHeight: '0.72',
                                        textShadow: '0px 1px 1px #00000071',
                                    },
                                    [theme.breakpoints.between('lg', 1400)]: {
                                        fontSize: `4rem`,
                                        letterSpacing: `-1.8px`,
                                        lineHeight: '0.72',
                                        textShadow: '0px 1px 1px #00000071',
                                    },
                                }}
                            >
                                {t('career.overlay')}
                            </Typography>
                        </Box>
                    </div>
                </Box>
                {/* サブタイトル */}
                <Box sx={{
                    paddingTop: '52px',
                    paddinngbottom: '21px',
                    [theme.breakpoints.down('sm')]: {
                        padding: '16px 0px 12px 0px',
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        padding: '21px 0px 10px 0px',
                    },
                }}>
                    <Typography
                        component="p"
                        sx={{
                            paddingBottom: '20px',
                            fontFamily: i18n.language === 'en' ? 'Inter' : 'Noto Sans JP',
                            fontWeight: i18n.language === 'en' ? '400' : '400',
                            fontSize: i18n.language === 'en' ? '45px' : '40px',
                            letterSpacing: i18n.language === 'en' ? '-1.35px' : '-1.2px',
                            lineHeight: '35px',
                            color: '#484848',
                            opacity: 1,
                            [theme.breakpoints.down('sm')]: {
                                paddingBottom: '0px',
                                fontSize: i18n.language === 'en' ? '22px' : '20px',
                                letterSpacing: i18n.language === 'en' ? `calc(-30 / 1000 * 22px)` : `calc(-30 / 1000 * 20px)`,
                                lineHeight: i18n.language === 'en' ? `calc(28 / 22)` : `calc(26 / 20)`,
                                textAlign: 'left'
                            },
                            [theme.breakpoints.between('sm', 'lg')]: {
                                paddingBottom: '0px',
                                fontSize: '24px',
                                letterSpacing: `calc(-30 / 1000 * 24px)`,
                                lineHeight: `calc(35 / 24)`,
                            },
                        }}
                    >
                        {t('career.subtitle')}
                    </Typography>
                </Box>
                {/* ボディメッセージ */}
                <Typography component="p" variant="body1"
                    sx={{
                        textAlign: 'left',
                        padding: '0px 0px 52px 0px',
                        [theme.breakpoints.down('sm')]: {
                            padding: '0px 0px 32.3px 0px',
                        },
                        [theme.breakpoints.between('sm', 'lg')]: {
                            padding: '0px 0px 20px 0px',
                            width: '555px',
                            margin: '0 auto'
                        },
                    }}
                >
                    {t('career.body')}
                </Typography>
                <Box sx={{
                    position: 'relative'
                }}>
                    <Box sx={{
                        position: 'absolute',
                        left: "50%",
                        transform: 'translate(-68.5px, 0%)', // transform: 'translate(-50%, 0%)',
                        [theme.breakpoints.down('sm')]: {
                            transform: 'translate(-52.455px, 0%)', // transform: 'translate(-50%, 0%)',
                        },
                    }}>
                        <HoverLinkedButton
                            imgBefore="images/util/readmorebefore.svg"
                            imgAfter="images/util/readmoreafter.svg"
                            link='https://www.wantedly.com/companies/company_9755414' />
                    </Box>
                </Box>
            </Container >
        </BottomLineBox >
    );
}
