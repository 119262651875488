import { Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { BaseBoxWhite } from "../util/BaseBox";
import { BorderBottomTypography } from '../util/BorderBottomTypography';


export default function CustomerValue() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const containerStyles = {
        padding: '0px 0px 149px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 0px 99px 0px'
        }
    };

    return (
        <BaseBoxWhite containerStyles={containerStyles}>
            <BorderBottomTypography containerStyles={{}}>
                {t('walccare.customerValue.title')}
            </BorderBottomTypography>
            <Typography
                component="h2"
                variant="h2"
                sx={{
                    padding: "30px 0px 24px 0px",
                    [theme.breakpoints.down('sm')]: {
                        padding: '12px 0px 12px 0px'
                    }
                }}
            >
                {t('walccare.customerValue.subtitle')}
            </Typography>
            <Typography
                component='p'
                variant="body1"
            >
                <Trans i18nKey="walccare.customerValue.body">
                </Trans>
            </Typography>
        </BaseBoxWhite>
    );
}
