import React, { useEffect } from 'react';


export function ImageViewerBottom({ children }) {
    const parentContainerStyle: React.CSSProperties = {
        width: '100%',
        height: '20px',
        overflow: 'hidden',
        borderBottomRightRadius: '15px',
        borderBottomLeftRadius: '15px',
        backgroundColor: 'rgba(167, 176, 177, 0.13)'
    };

    const imageContainerStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        overflowX: 'scroll',
    };

    return (
        <div style={parentContainerStyle}>
            <div style={imageContainerStyle}>
                {children}
            </div>
        </div>
    );
};


export function ImageViewerTop({ children }) {
    const parentContainerStyle: React.CSSProperties = {
        width: '100%',
        height: '15px',
        overflow: 'hidden',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        backgroundColor: 'rgba(167, 176, 177, 0.13)'
        // paddingTop: "28px",
    };

    const imageContainerStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        overflowX: 'scroll',
    };

    return (
        <div style={parentContainerStyle}>
            <div style={imageContainerStyle}>
                {children}
            </div>
        </div>
    );
};


export function ImageViewer({ id, scrollPosition, children }) {

    useEffect(() => {
        console.log(id + ' slide use Effect')
        const element = document.getElementById(id);
        if (element) {
            if (scrollPosition == 'start') {
                element.scrollLeft = 0
            }
            // 画像が左右対称じゃないので、目視で15pxずらしました
            else if (scrollPosition == 'center') {
                element.scrollLeft = (element.scrollWidth - element.clientWidth) / 2 + 15
            }
            else if (scrollPosition == 'end') {
                element.scrollLeft = element.scrollWidth
            }
            else {
                element.scrollLeft = 0
            }
            // console.log(element.offsetWidth) // 298
            // console.log(element.clientWidth) // 要素の横幅
            // console.log(element.scrollWidth) // imageの横幅
            // console.log(element.scrollLeft)
        }
    }, []);

    const parentContainerStyle: React.CSSProperties = {
        width: '100%',
        height: '205px',
        overflow: 'hidden',
        backgroundColor: 'rgba(167, 176, 177, 0.13)',
        display: 'relative',
    };

    const imageContainerStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        overflowX: 'scroll',
    };

    return (
        <div
            style={parentContainerStyle}>
            <div
                id={id}
                style={imageContainerStyle}>
                {children}
            </div>
        </div>
    );
};
