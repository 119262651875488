import { Backdrop } from '@mui/material';


export default function Splash({ showSplash }) {
    return (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: '#EAF6F7',
            }}
            open={showSplash}
            transitionDuration={{
                enter: 0,
                exit: 900
            }}
        >
            <img src="/images/header/logowhite.svg"
                alt="logo"
                width={115}
                style={{
                    verticalAlign: 'bottom',
                }}
            />
        </Backdrop>
    )
}