import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';


export function BorderBottomTypography({ children, containerStyles }) {
    const theme = useTheme();

    return (
        <Box
            width='100%'
            sx={{
                paddingBottom: '14px',
                borderBottom: '1px solid rgba(112, 112, 112, 0.2)',
                [theme.breakpoints.down('sm')]: {
                    paddingBottom: '12px',
                },
            }}>
            <Typography component="h1" variant="h1" >
                {children}
            </Typography>
        </Box>
    )
};