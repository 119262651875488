import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { BaseBox } from '../component-parts/util/BaseBox';

export default function NotFound({ setPage }) {
    const theme = useTheme();
    React.useEffect(() => {
        setPage('notfound')
    }, []);

    return (
        <BaseBox
            sx={{
                padding: '200px 0px 200px 0px'
            }}
        >
            <Typography variant='h1'
                sx={{
                    'WebkitTextFillColor': 'unset',
                    paddingRight: '2px', // グラーデション+inline-blockで文字が見切れるため。
                    display: 'inline-block',
                    [theme.breakpoints.down('sm')]: {
                        'WebkitTextFillColor': 'unset',
                    },
                }}>
                404 - Not Found
            </Typography>
            <br />
            <Typography variant='body1'>
                The page you are looking for does not exist.
            </Typography>
        </BaseBox>
    );
};