import { Box, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import LanguageButton from './LanguageButton';


const LinkHamburgerMenu = ({ href, pageTitle }) => {
    const theme = useTheme();
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
        }}>
            <Link
                color="inherit"
                noWrap
                key={href}
                variant="body1"
                underline="none"
                href={href}
                sx={{
                    p: 1,
                    flexShrink: 0,
                    padding: "0",
                    margin: "0",
                    display: 'block',
                    width: 'fit-content',  // 横幅を調整する
                }}
            >
                <Typography sx={{
                    fontFamily: 'Inter',
                    color: '#484848',
                    textAlign: 'right',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '20px',
                        fontWeight: '400',
                        letterSpacing: '-1.2px',
                        lineHeight: '1',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        fontSize: '39px',
                        fontWeight: '400',
                        letterSpacing: '-1.2px',
                        lineHeight: '1',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                    },
                }}>
                    {pageTitle}
                </Typography>
            </Link>
        </Box >
    )
}


export default function HamburgerMenu({ toggleDrawer, startSplash }) {
    const theme = useTheme();
    return (
        <React.Fragment>
            <Box
                sx={{
                    padding: '0px 25px 0 25px',
                    [theme.breakpoints.between('sm', 'lg')]: {
                        padding: '0px 0px 0px 0px',
                    }
                }}
            >
                <Box sx={{
                    padding: '13px 0px 0px 0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    [theme.breakpoints.between('sm', 'lg')]: {
                        padding: '24px 42px 0px 40px',
                    }
                }}>
                    <Box
                        component='img'
                        src="/images/header/logoblack.svg"
                        alt="logo"
                        sx={{
                            verticalAlign: 'bottom',
                            [theme.breakpoints.between('sm', 'lg')]: {
                                height: '40px',
                            }
                        }}
                    />
                    <IconButton
                        onClick={toggleDrawer(false)} // バツボタンをクリックしたら閉じる
                        sx={{
                            padding: '0px 0px 0px 0px',
                            marginLeft: 'auto', // 右寄せ
                        }}
                    >
                        <Box
                            component='img'
                            src="/images/header/closeicon.svg"
                            alt="close"
                            sx={{
                                verticalAlign: 'bottom',
                                [theme.breakpoints.between('sm', 'lg')]: {
                                    height: '37px',
                                }
                            }}
                        />
                    </IconButton>
                </Box>
                <Box sx={{
                    [theme.breakpoints.down('sm')]: {
                        padding: '17vw 0px 0 0px',
                        textAlign: 'right',
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        padding: '121px 52px 0px 0px',
                        textAlign: 'right',
                    },
                }}>
                    <LinkHamburgerMenu href={'/'} pageTitle={'TOP'} />
                    <LinkHamburgerMenu href={'/walccare'} pageTitle={'WALC CARE'} />
                    <LinkHamburgerMenu href={'/contact'} pageTitle={'CONTACT'} />
                    <LinkHamburgerMenu href={'/policy'} pageTitle={'POLICY'} />
                    <LanguageButton startSplash={startSplash} color={""} />
                </Box>
            </Box>
        </React.Fragment >
    )
};