import { Box, Typography, useTheme } from '@mui/material';


export default function WalccareIcon({ t, textPath, i, img }) {
    const theme = useTheme()
    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <Box
                width={128}
                height={128}
                component="img"
                src={img}
                alt="icon"
                sx={{
                    width: '128px',
                    [theme.breakpoints.down('sm')]: {
                        width: '87px',
                        height: '87px'
                    }
                }}>
            </Box>
            <Box sx={{
                paddingLeft: '31px',
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: '14px',
                }
            }}>
                <Typography component="h3" variant="h3"
                    sx={{
                        paddingBottom: '7.7px',
                        [theme.breakpoints.down('sm')]: {
                        }
                    }}
                >
                    {t(`${textPath}.${i}.title`)}
                </Typography>
                <Typography component="p" variant="body2">
                    {t(`${textPath}.${i}.body`)}
                </Typography>
            </Box>
        </Box >
    )
}
