import { createTheme, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


export default function WalcCareTheme() {
    const { t, i18n } = useTranslation();
    const lang = i18n.language.split('-')[0];

    const getFontFamily = (lang: string): string => {
        return lang === 'ja' ? 'Noto Sans JP' : 'Inter';
    };
    const theme = useTheme();

    const globalTheme = () => createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1900, //ブラウザのスクロールバー分、ビューポートが狭くなる
            },
        },
        palette: {
            background: {
                default: '#EAF6F7', // ページの背景色を指定する
            },
        },
        typography: {
            // h1はtitle
            // 常に英語
            h1: {
                fontFamily: "Inter",
                fontSize: "50px",
                fontWeight: 'normal',
                letterSpacing: "-3px",
                background: 'linear-gradient(120deg, #00C7D8 0%, #006490 100%)', // XDと異なるが、デザイナーから指定された値
                "WebkitBackgroundClip": 'text',
                'WebkitTextFillColor': 'transparent',
                paddingRight: '2px', // グラーデション+inline-blockで文字が見切れるため。
                display: 'inline-block',
                [theme.breakpoints.down('sm')]: {
                    fontFamily: "Inter",
                    fontSize: '28px', // smサイズ以下の場合のフォントサイズ
                    fontWeight: 'normal',
                    letterSpacing: "-1.68px",
                    lineHeight: `calc(29 / 28)`,
                    background: 'linear-gradient(120deg, #00C7D8 0%, #006490 100%)', // XDと異なるが、デザイナーから指定された値
                    "WebkitBackgroundClip": 'text',
                    'WebkitTextFillColor': 'transparent',
                    paddingRight: '2px',
                    display: 'inline-block',
                },
                [theme.breakpoints.between('sm', "lg")]: {
                    fontSize: '32px',
                    letterSpacing: `calc(-60 / 1000 * 32px)`,
                    lineHeight: `calc(34 / 32)`,
                    paddingRight: '2px',
                    display: 'inline-block',
                },
            },
            // h2はsubtitle
            // 日英が切り替わる
            h2: {
                fontFamily: getFontFamily(lang), // ページの言語に応じてフォントファミリーを切り替える
                fontStyle: 'normal',
                fontWeight: i18n.language === 'en' ? '400' : '400',
                fontSize: i18n.language === 'en' ? '68px' : '60px',
                lineHeight: i18n.language === 'en' ? '75px' : '75px',
                letterSpacing: i18n.language === 'en' ? '-2.04px' : '-1.8px',
                color: "#484848",
                opacity: 1,
                [theme.breakpoints.down('sm')]: {
                    fontSize: i18n.language === 'en' ? '30px' : '28px',
                    letterSpacing: i18n.language === 'en' ? '-0.09px' : '-0.84px',
                    lineHeight: i18n.language === 'en' ? `calc(38 / 30)` : `calc(40 / 28)`,
                },
                [theme.breakpoints.between('sm', "lg")]: {
                    fontSize: i18n.language === 'en' ? '38px' : '35px',
                    letterSpacing: i18n.language === 'en' ? `calc(-30 / 1000 * 38px)` : `calc(-30 / 1000 * 35px)`,
                    lineHeight: i18n.language === 'en' ? `calc(46 / 38)` : `calc(50 / 35)`,
                },
            },
            h3: {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: "60px",
                lineHeight: '75px',
                letterSpacing: "-1.8px",
                color: "#484848",
                opacity: 1
            },
            body1: {
                fontFamily: getFontFamily(lang), // ページの言語に応じてフォントファミリーを切り替える
                fontStyle: 'normal',
                fontWeight: i18n.language === 'en' ? '400' : '500',
                fontSize: "20px", // フォントサイズを指定する
                lineHeight: i18n.language === 'en' ? '30px' : '32px',
                letterSpacing: i18n.language === 'en' ? '0px' : '0.4px',
                color: "#515D6A",
                opacity: 1,
                [theme.breakpoints.down('sm')]: {
                    fontSize: i18n.language === 'en' ? '15px' : '15px',
                    fontWeight: i18n.language === 'en' ? '400' : '400',
                    letterSpacing: i18n.language === 'en' ? '0px' : '0.3px',
                    lineHeight: i18n.language === 'en' ? `calc(22 / 15)` : `calc(25 / 15)`,
                },
                [theme.breakpoints.between('sm', "lg")]: {
                    fontSize: i18n.language === 'en' ? '14px' : '14px',
                    letterSpacing: i18n.language === 'en' ? `calc(15 / 1000 * 14px)` : `calc(15 / 1000 * 14px)`,
                    lineHeight: i18n.language === 'en' ? `calc(22 / 14)` : `calc(25 / 14)`,
                },
            },
            body2: {
                fontFamily: getFontFamily(lang), // ページの言語に応じてフォントファミリーを切り替える
                fontStyle: 'normal',
                fontWeight: i18n.language === 'en' ? '400' : '500',
                fontSize: i18n.language === 'en' ? '15px' : '14px',
                lineHeight: i18n.language === 'en' ? '22px' : '25px',
                letterSpacing: i18n.language === 'en' ? '0px' : '0.28px',
                color: '#515D6A',
                opacity: 1,
                [theme.breakpoints.down('sm')]: {
                    fontSize: i18n.language === 'en' ? '11px' : '10px',
                    fontWeight: i18n.language === 'en' ? '400' : '400',
                    letterSpacing: i18n.language === 'en' ? '0px' : '0.08px',
                    lineHeight: i18n.language === 'en' ? `calc(15 / 11)` : `calc(15 / 10)`,
                },
                [theme.breakpoints.between('sm', "lg")]: {
                    fontSize: i18n.language === 'en' ? '11px' : '12px',
                    letterSpacing: i18n.language === 'en' ? "0" : `calc(15 / 1000 * 12px)`,
                    lineHeight: i18n.language === 'en' ? `calc(16 / 11)` : `calc(18 / 12)`,
                },
            },
        },
    });
    return globalTheme
}
