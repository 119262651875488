import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { BaseBox } from '../util/BaseBox';


const AccessSMElement = ({ t, element }) => {
    const theme = useTheme();
    return (
        <Box
            minHeight={59}
            maxHeight={87}
            sx={{
                borderBottom: '1px solid #ccc',
            }}
        >
            <Typography component="p" variant='inherit'
                sx={{
                    padding: '10px 0 7px 0px',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    letterSpacing: `calc(15 / 1000 * 12px)`,
                    [theme.breakpoints.between('sm', 'lg')]: {
                        fontSize: '14px',
                        letterSpacing: `calc(15 / 1000 * 14px)`,
                        lineHeight: `calc(22 / 14px)`,
                    },
                }}
            >
                {t(`access.${element}Key`)}
            </Typography>
            <Typography component="p" variant='inherit'
                sx={{
                    padding: '0px 0px 10px 0px',
                    fontSize: '14px',
                    letterSpacing: `calc(15 / 1000 * 14px)`,
                    [theme.breakpoints.between('sm', 'lg')]: {
                        fontSize: '14px',
                        letterSpacing: `calc(15 / 1000 * 14px)`,
                        lineHeight: `calc(24 / 14px)`,
                    },
                }}
            >
                {t(`access.${element}Value`)}
            </Typography>
        </Box>
    )
}


export default function Access() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
    const { t, i18n } = useTranslation();
    const embedUrl = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3241.872046077375!2d139.69937285902486!3d35.65552400914863!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bc839cef60b%3A0x6ba76749f7eff38f!2z5qCq5byP5Lya56S-V0FMQw!5e0!3m2!1s${i18n.language}!2sjp!4v1684224798295!5m2!1s${i18n.language}!2sjp`;

    function getWidth() {
        const accessTableWidth = i18n.language == 'en' ? 1100 : 800
        const muiGridSize = 12
        let accessKeyWidth, accessValueWidth
        if (i18n.language == 'en') {
            accessKeyWidth = (365 / accessTableWidth) * muiGridSize;
            accessValueWidth = (735 / accessTableWidth) * muiGridSize;
        }
        else {
            accessKeyWidth = (330 / accessTableWidth) * muiGridSize;
            accessValueWidth = (470 / accessTableWidth) * muiGridSize;
        }
        return {
            accessTableWidth,
            accessKeyWidth,
            accessValueWidth,
        };
    }

    const { accessTableWidth, accessKeyWidth, accessValueWidth } = getWidth();

    return (
        <BaseBox
            sx={{
                paddingTop: '140px',
                paddingBottom: '140px',
                [theme.breakpoints.down('sm')]: {
                    paddingTop: '72px',
                    paddingBottom: '69.5px'
                },
                [theme.breakpoints.between('sm', 'lg')]: {
                    paddingTop: '73px',
                    paddingBottom: '73px'
                },
            }}>
            <Typography component="h1" variant="h1">
                {t('access.title')}
            </Typography>
            {/* Lg以下 */}
            {isDownLg ? (
                <Box
                    sx={{
                        padding: '11.1px 0px 28.2px 0px',
                        [theme.breakpoints.between('sm', 'lg')]: {
                            padding: '44px 60px 35px 60px',
                        },
                    }}
                >
                    <AccessSMElement t={t} element={'address'} />
                    <AccessSMElement t={t} element={'tel'} />
                    <AccessSMElement t={t} element={'access'} />
                </Box>) :
                (<Box
                    display="flex"
                    flexDirection="column"
                    margin='auto'
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '41.9px 0px 36.5px 0px',
                        [theme.breakpoints.down('sm')]: {
                            padding: '0px 0px 0px 0px',
                        },
                    }}
                >
                    <Box
                        width={accessTableWidth}
                    >
                        <Grid container
                            rowSpacing={0}
                            columnSpacing={0}
                        >
                            <Grid item lg={accessKeyWidth}
                                sx={{
                                    borderBottom: '1px solid #ccc',
                                    paddingY: "8px"
                                }}
                            >
                                <Typography>
                                    {t('access.addressKey')}
                                </Typography>
                            </Grid>
                            <Grid item lg={accessValueWidth}
                                sx={{
                                    borderBottom: '1px solid #ccc',
                                    paddingY: "8px"
                                }}
                            >
                                <Typography>
                                    {t('access.addressValue')}
                                </Typography>
                            </Grid>
                            <Grid item lg={accessKeyWidth}
                                sx={{
                                    borderBottom: '1px solid #ccc',
                                    paddingY: "8px"
                                }}>
                                <Typography>
                                    {t('access.telKey')}
                                </Typography>
                            </Grid>
                            <Grid item lg={accessValueWidth}
                                sx={{
                                    borderBottom: '1px solid #ccc',
                                    paddingY: "8px"
                                }}>
                                <Typography>
                                    {t('access.telValue')}
                                </Typography>
                            </Grid>
                            <Grid item lg={accessKeyWidth}
                                sx={{
                                    borderBottom: '1px solid #ccc',
                                    paddingY: "8px"
                                }}>
                                <Typography>
                                    {t('access.accessKey')}
                                </Typography>
                            </Grid>
                            <Grid item lg={accessValueWidth}
                                sx={{
                                    borderBottom: '1px solid #ccc',
                                    paddingY: "8px"
                                }}>
                                <Typography>
                                    {t('access.accessValue')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>)
            }
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    [theme.breakpoints.between('sm', 'lg')]: {
                        paddingX: '60px'
                    }
                }}
            >
                <iframe
                    src={embedUrl}
                    width={isMobile ? "100%" : "800"}
                    height={isMobile ? "300" : "600"}
                    frameBorder={0}
                    style={{
                        border: 0,
                        WebkitFilter: "grayscale(100%)",
                        filter: "grayscale(100%)",
                        msFilter: "grayscale(100%)",
                    }}
                    allowFullScreen={true}
                    aria-hidden="false"
                    tabIndex={0}
                />
            </Box>
        </BaseBox >
    );
}