import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { BaseBox } from "../util/BaseBox";
import { BorderBottomTypography } from '../util/BorderBottomTypography';
import { CollectAndDiagnoseMainImageSP } from './CollectAndDiagnoseMainImage';
import { ImageViewer, ImageViewerBottom } from './ImageView';
import WalccareIcon from './WalccareIcon';


export default function CollectAndDiagnose() {
    const theme = useTheme();
    const { t } = useTranslation();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
    const mainImagePath = "/images/walccare/WALCCARE_Collect and Diagnosis.svg"

    return (
        <BaseBox
            sx={{
                padding: "105.5px 0px 100px 0px",
                [theme.breakpoints.down('sm')]: {
                    paddingTop: "46px",
                    paddingBottom: '0px'
                }
            }}
        >
            <BorderBottomTypography containerStyles={{}}>
                {t(`walccare.collectAndDiagnose.title`)}
            </BorderBottomTypography>
            {isDownSm ? (
                <Box >
                    <Box display='flex' paddingTop='28px'>
                        <Box sx={{
                            width: "15px",
                            backgroundColor: 'rgba(167, 176, 177, 0.13)',
                            borderTopLeftRadius: '15px'
                        }}></Box>
                        < ImageViewer id='collectAndDiagnoseImage' scrollPosition='center'>
                            <CollectAndDiagnoseMainImageSP sx={{
                                height: '100%',
                                width: 'auto',
                                maxWidth: 'none',
                                verticalAlign: 'bottom',
                            }} />
                        </ ImageViewer>
                        <Box sx={{
                            width: "15px",
                            backgroundColor: 'rgba(167, 176, 177, 0.13)',
                            borderTopRightRadius: '15px'
                        }}></Box>
                    </Box>
                    < ImageViewerBottom>
                    </ImageViewerBottom>
                </Box>
            ) : (
                <Box display="none"></Box>
            )}
            <Typography component="h2" variant="h2" sx={{
                padding: '20px 0px 30px 0',
                [theme.breakpoints.down('sm')]: {
                    padding: "20px 0px 20px 0",
                }
            }}
            >
                {t(`walccare.collectAndDiagnose.subtitle`)}
            </Typography>
            <Typography component="p" variant="body1" sx={{
                paddingBottom: "27px",
                [theme.breakpoints.down('sm')]: {
                    paddingBottom: "62px"
                }
            }}
            >
                {t(`walccare.collectAndDiagnose.body`)}
            </Typography>
            {!isDownSm ? (
                <img src={mainImagePath} alt="Description"
                    style={{
                        width: '100%',
                        height: 'auto',
                        paddingBottom: '69px',
                    }} />
            ) : (<Box display="none"></Box>)}
            <Grid container
                rowSpacing={{ xs: 38 / 8, lg: 87 / 8 }}
                columnSpacing={110 / 8}>
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.collectAndDiagnose.icons'} i={0} img={"/images/walccare/Icon_02_01.svg"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.collectAndDiagnose.icons'} i={1} img={"/images/walccare/Icon_02_02.svg"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.collectAndDiagnose.icons'} i={2} img={"/images/walccare/Icon_02_03.svg"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.collectAndDiagnose.icons'} i={3} img={"/images/walccare/Icon_02_04.svg"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.collectAndDiagnose.icons'} i={4} img={"/images/walccare/Icon_02_05.svg"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.collectAndDiagnose.icons'} i={5} img={"/images/walccare/Icon_02_06.svg"} />
                </Grid>
            </Grid>
        </BaseBox>
    );
}
