import 'animate.css';
import { useInView } from 'react-intersection-observer';
import './FadeInImage.css';

interface FadeInImageProps {
    src: string;
    alt: string;
    style?: React.CSSProperties; // style プロパティをオプションに変更
}

const FadeInImage = ({ src, alt, style }: FadeInImageProps) => {
    const { ref, inView } = useInView({
        // オプション
        triggerOnce: true, // 最初の一度だけ実行
        threshold: 0.2, // 画像が20%表示されたらトリガーするように設定
    });

    return (
        <div>
            {/* refを与えた要素がウインドウ内に現れるとinViewがtrue*/}
            <div ref={ref}>
                {inView && (
                    <div
                        className="animate__animated animate__fadeInUp"
                    >
                        <img src={src}
                            alt={alt}
                            style={style}
                            className="fade-in-image" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FadeInImage;