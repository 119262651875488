import { Box, Typography, useTheme } from '@mui/material';
import Link from '@mui/material/Link';


export default function Footer() {
  const theme = useTheme();

  return (
    // page-footer
    // 背景色を決める
    <Box
      sx={{
        bgcolor: 'background.paper',
        height: '515px',
        background: 'linear-gradient(120deg, #00C7D8 0%, #006490 100%)', // XDと異なるが、デザイナーから指定された値
        color: '#ffffff',
        [theme.breakpoints.down('sm')]: {
          height: '230.8px',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          height: '368px',
        },
      }}
    >
      {/* footer-container */}
      {/* コンテナの幅、ビューポートの中央配置、上下空きスペースの設定を行う */}
      <Box
        sx={{
          maxWidth: "1520px",
          margin: "0 auto",
          padding: "97.15px 0 0 0",
          [theme.breakpoints.down('sm')]: {
            paddingTop: '17px',
            paddingRight: `${26 / 390 * 100}%`,
            paddingLeft: `${26 / 390 * 100}%`,
          },
          [theme.breakpoints.between('sm', 'lg')]: {
            paddingTop: '36px',
            paddingX: `${26 / 390 * 100}%`,
          },
          [theme.breakpoints.between('lg', 'xl')]: {
            paddingX: `${142 * 100 / 1366}vw`,
          },
        }}
      >
        {/* 上部 */}
        <Box
          height={321.85}
          sx={{
            padding: "0px 0px 0px 0px",
            [theme.breakpoints.down('sm')]: {
              height: '185.6px'
            },
            [theme.breakpoints.between('sm', 'lg')]: {
              height: '277px'
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: 'space-between',
            }}
          >
            {/* ロゴ */}
            <Box
              sx={{
                maxWidth: "113.4px",
                [theme.breakpoints.down('sm')]: {
                  maxWidth: "46.62px",
                },
                [theme.breakpoints.between('sm', 'lg')]: {
                  maxWidth: "67px",
                },
              }}
            >
              <Link href="/" underline="none" color="inherit">
                <img src="/images/footer/logo.svg" alt="logo"
                  style={{
                    maxWidth: '100%',
                    verticalAlign: 'bottom',
                  }}
                />
              </Link>
            </Box>
            {/* リンク */}
            <Box>
              <Box
                sx={{
                  margin: "0",
                  padding: "0",
                  listStyle: "none",
                }}
              >
                <Link
                  color="inherit"
                  noWrap
                  key="policy"
                  variant="body1"
                  underline="none"
                  href="/policy"
                  sx={{
                    p: 1,
                    flexShrink: 0,
                    padding: "0",
                    margin: "0"
                  }}
                >
                  <Typography
                    sx={{
                      font: 'normal normal bold 14px/17px Inter',
                      textAlign: 'right',
                      letterSpacing: '0px',
                      color: "#FAFFFF",
                      opacity: 1,
                      [theme.breakpoints.down('sm')]: {
                        font: 'normal normal bold 9px/11px Inter',
                        letterSpacing: '0px'
                      },
                    }}
                  >
                    Policy
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* 下部 コピーライト*/}
        <Box
          sx={{
            padding: "0",
            margin: "0"
          }}
        >
          <Typography
            sx={{
              font: 'normal normal normal 15px/15px Inter',
              color: '#FAFFFF',
              textAlign: 'right',
              letterSpacing: '-0.45px',
              opacity: 1,
              [theme.breakpoints.down('sm')]: {
                font: 'normal normal normal 10px/10px Inter',
                color: '#FAFFFF',
                textAlign: 'right',
                letterSpacing: '-0.3px',
              },
            }}
          >
            Copyright © 2023 WALC Inc. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box >
  );
}