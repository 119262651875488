import { Box, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HamburgerMenu from '../component-parts/header/HamburgerMenu';
import HamburgerMenuSVG from '../component-parts/header/HamburgerMenuSVG';
import HeaderVideo from '../component-parts/header/HeaderVideo';
import LanguageButton from '../component-parts/header/LanguageButton';


interface HeaderProps {
  sections: ReadonlyArray<{
    title: string;
    url: string;
    imageUrl: string;
  }>;
  sectionsMap: Map<string, Map<string, string | boolean>>,
  page: string,
  startSplash: Function;
  startHeaderVideo: number;
}


const HeaderBachgroundVideo = ({ theme, t, isVideoBackground, isImageBackgroud, isNoBackground, imageLink, headerTitle, headerMessage, startHeaderVideo }) => {
  return (
    <Box sx={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      maxWidth: "1920px",
      display: 'inline-Block',
    }} >
      {
        isVideoBackground ? (
          <React.Fragment>
            {/* Vimeo */}
            <HeaderVideo vimeoId="831082734" startHeaderVideo={startHeaderVideo} />
            {/* 動画背景に重ねる文字列 */}
            < Box sx={{
              position: 'absolute',
              // TODO: 位置が微妙。
              top: `calc(${739 / 1080 * 100}%)`,
              left: `calc(${63 / 1920 * 100}%)`,
              color: 'white',
              fontSize: '24px',
              zIndex: 1000,
              [theme.breakpoints.down('sm')]: {
                visibility: 'hidden',
              },
            }}>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: '5.2vw',
                  fontWeight: 'normal',
                  lineHeight: '90%',
                  fontFamily: 'Inter',
                  letterSpacing: '-0.06em',
                  color: '#FAFFFF',
                  opacity: 1,
                  [theme.breakpoints.up('xl')]: {
                    fontSize: '100px',
                    letterSpacing: '-6px'
                  },
                }}
              >
                We make<br />the future of<br />manufacturing industry.
              </Typography>
            </Box >
          </React.Fragment>
        ) : isImageBackgroud ? (
          <React.Fragment>
            < Box sx={{
              position: 'absolute',
              top: `calc(${335 / 726 * 100}%)`,
              left: `calc(${200 / 1920 * 100}%)`,
              zIndex: 1000,
              [theme.breakpoints.down('sm')]: {
                visibility: 'hidden',
              },
            }}>
              <Typography
                padding='0px 0px 15px 0px'
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Inter',
                  fontWeight: 'normal',
                  fontSize: `calc(100 / 1920 * 100vw)`,
                  lineHeight: '90%',
                  letterSpacing: '-0.06em',
                  color: '#FAFFFF',
                  textShadow: '4px 4px 8px #0000004D',
                  [theme.breakpoints.up('xl')]: {
                    fontSize: '100px',
                    letterSpacing: '-0.06em',
                  },
                }}
              >
                {t(headerTitle)}
              </Typography>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Inter',
                  fontWeight: 'normal',
                  fontSize: `calc(20 / 1920 * 100vw)`,
                  letterSpacing: '0',
                  color: '#FAFFFF',
                  textShadow: '1px 1px 4px #00000099',
                  [theme.breakpoints.up('xl')]: {
                    fontSize: '20px',
                    letterSpacing: '0'
                  },
                }}
              >
                {t(headerMessage)}
              </Typography>
            </Box>
            <img
              src={imageLink}
              alt="Image"
              style={{
                maxHeight: '726px',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </React.Fragment>
        ) : (
          <Box display='none'></Box>
        )
      }
    </Box >
  )
}

const HeaderBachgroundVideoLg = ({ theme, isVideoBackground, isImageBackgroud, isNoBackground, imageLink, startHeaderVideo }) => {
  return (
    <Box>
      {
        isVideoBackground ? (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'inline-Block',
            }}
          >
            {/* Vimeo */}
            <HeaderVideo vimeoId="856593231" startHeaderVideo={startHeaderVideo} />
            {/* 動画背景に重ねる文字列 */}
            < Box sx={{
              position: 'absolute',
              // top: 0,
              top: `calc(${267 / 390 * 100}%)`,
              left: `calc(${16 / 390 * 100}%)`,
              zIndex: 1000,
            }}>
              <Typography
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    textAlign: 'left',
                    fontSize: `calc(36 / 390 * 100vw)`,
                    fontWeight: 'normal',
                    fontFamily: 'Inter',
                    lineHeight: `calc(32 / 36)`,
                    letterSpacing: '-0.06em',
                    color: '#FAFFFF',
                    opacity: 1,
                  },
                  [theme.breakpoints.between('sm', 'lg')]: {
                    textAlign: 'left',
                    fontSize: `calc(36 / 390 * 100vw)`,
                    fontWeight: 'normal',
                    fontFamily: 'Inter',
                    lineHeight: `calc(32 / 36)`,
                    letterSpacing: '-0.06em',
                    color: '#FAFFFF',
                    opacity: 1,
                  },
                }}
              >
                We make<br />the future of<br />manufacturing industry.
              </Typography>
            </Box>
          </Box>
        ) : isImageBackgroud ? (
          <Box
            component="img"
            position='absolute'
            top={0}
            left={0}
            sx={{
              width: '100%',
              aspectRatio: '1 / 1',
              // maxHeight: { xs: 233, md: 167 },
              // maxWidth: { xs: 350, md: 250 },
              objectFit: 'cover',
              verticalAlign: 'bottom',
            }}
            alt="State of the art machine tools."
            src={imageLink}
          />
        ) : (
          <Box display="none"></Box>
        )
      }
    </Box >
  )
};

const HeaderNavigationLink = ({ section, isFirst, isNoBackground }) => {
  const color = isNoBackground == true ? "rgba(81, 93, 106, 0.75)" : "rgba(255, 255, 255, 1.0)"
  const fontColor = isNoBackground == true ? "rgba(81, 93, 106, 0.75)" : '#FAFFFF'

  return (
    <div>
      <Link
        height={'99%'}
        color="inherit"
        noWrap
        key={section.title}
        variant="body1"
        underline="none"
        href={section.url}
        borderBottom='1px solid rgba(0, 0, 0, 0)'
        sx={{
        }}
      >
        <Box
          sx={{
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: '-42px',
              left: 0,
              width: 0,
              height: '3px',
              backgroundColor: fontColor,
              WebkitTransition: 'width 0.2s ease-in-out',  // この行を追加
            },
            '&:hover::before': {
              width: '100%',
            },
          }}
        >
          <Typography sx={{
            textAlign: 'left',
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: `calc(26 / 14)`,
            letterSpacing: '0px',
            color: fontColor,
            padding: '0px 31px 0px 31px',
            margin: '0',
            borderLeft: isFirst ? `1px solid ${color}` : 'none',
            borderRight: `1px solid ${color}`,
          }}>
            {section.title}
          </Typography>
        </Box>
      </Link>
    </div >
  )
}

export default function Header(props: HeaderProps) {
  const { t, i18n } = useTranslation();
  const { sections, sectionsMap, page, startSplash, startHeaderVideo } = props;
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  // sm以下ではドロワーを表示する
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsOpen(open);
  };

  const pageInfo = sectionsMap.get(page)
  const title = pageInfo!.get('title')
  // const imageUrl = pageInfo!.get('imageUrl')
  const imageUrl = isDownLg ? pageInfo!.get('imageUrlMobile') : pageInfo!.get('imageUrl')
  const isVideoBackground: boolean = !!pageInfo?.get('isVideoBackground')
  const isImageBackgroud: boolean = !!pageInfo?.get('isImageBackgroud')
  const isNoBackground: boolean = !!pageInfo?.get('isNoBackground')

  function getAspectRatio(
    isVideoBackground: boolean,
    isImageBackground: boolean,
    isNoBackground: boolean,
    isDownLg: boolean
  ): string {
    if (isDownLg && isNoBackground) {
      // スマホのヘッダーサイズは基本的に1:1だが、画像背景がないヘッダーのみこの比率
      return '390 / 55'
    } else if (isDownLg) {
      // スマホのヘッダーサイズは基本的に1:1
      return '390 /390'
    } else if (isVideoBackground) {
      return "1920 / 1080";
    } else if (isImageBackground) {
      return "1920 / 726";
    } else if (isNoBackground) {
      return "1920 / 111";
    }
    else {
      return "1920 / 1080"
    }
  }
  const aspectRatio = getAspectRatio(isVideoBackground, isImageBackgroud, isNoBackground, isDownLg)
  const color = isNoBackground == true ? "rgba(81, 93, 106, 0.75)" : "rgba(255, 255, 255, 1.0)"

  return (
    <Box
      display="flex"
      justifyContent="center"
      position="relative"
    >
      <Box
        sx={{
          top: "0px",
          left: "0px",
          aspectRatio: aspectRatio,
          width: "100%",
          maxWidth: "1920px",
          margin: 'auto',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          opacity: 1,
        }}
      >
        {isDownLg ? (
          <HeaderBachgroundVideoLg
            theme={theme}
            isVideoBackground={isVideoBackground}
            isImageBackgroud={isImageBackgroud}
            isNoBackground={isNoBackground}
            imageLink={imageUrl}
            startHeaderVideo={startHeaderVideo} />
        ) : (
          <HeaderBachgroundVideo
            theme={theme}
            t={t}
            isVideoBackground={isVideoBackground}
            isImageBackgroud={isImageBackgroud}
            isNoBackground={isNoBackground}
            headerTitle={pageInfo!.get('headerTitle')}
            headerMessage={pageInfo?.get('headerMessage')}
            imageLink={imageUrl}
            startHeaderVideo={startHeaderVideo}
          />
        )}

        {/* ツールバー */}
        <Box
          color={isNoBackground == true ? 'black' : 'white'}
          sx={{
            position: 'relative',
            display: 'flex',
            height: '111px',
            margin: 'auto',
            marginX: '62px',
            borderBottom: `1px solid ${color}`,
            justifyContent: 'space-between',
            zIndex: 1000,
            [theme.breakpoints.down('sm')]: {
              height: '52px',
              marginX: '26px',
            },
            [theme.breakpoints.between('sm', 'lg')]: {
              height: '85px',
              marginX: '40px',
            },
          }}>
          {/* ロゴ */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Link href="/" underline="none" color="inherit">
              <Box
                component='img'
                src={isNoBackground ? "/images/header/logo_black.svg" : "/images/header/logo.svg"}
                alt="logo"
                sx={{
                  height: '37.5px',
                  verticalAlign: 'bottom',
                  [theme.breakpoints.down('sm')]: {
                    height: '28.63px'
                  },
                  [theme.breakpoints.between('sm', 'lg')]: {
                    height: '41px'
                  },
                }}
              />
            </Link>
          </Box>
          {/* lg以上の場合は普通のツールバー */}
          <Box
            sx=
            {{
              display: 'flex',
              alignItems: 'center',
              justifyContent: "flex-end",
              [theme.breakpoints.down('lg')]: {
                display: 'none', // lg以下では非表示にする
              },
            }}
          >
            <Toolbar
              component="nav"
              variant="dense"
              disableGutters
              sx={{
                justifyContent: 'space-between',
                overflowX: 'auto',
                padding: '0px 73.5px 0px 0px',
                height: '100%'
              }}
            >
              <Box
                display={'flex'}
                height='100%'
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  overflowX: 'auto',
                  // borderLeft: '1px solid rgba(255, 255, 255, 1.0)'
                }}>
                {sections.map((section, index) => (
                  <HeaderNavigationLink section={section} isFirst={index === 0} isNoBackground={isNoBackground} />
                ))}
              </Box>
            </Toolbar>
            <LanguageButton startSplash={startSplash} color={color} />
          </Box>
          {/* lg以下はハンバーガーメニュー */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center', // 高さ方向に中央揃え
              [theme.breakpoints.up('lg')]: {
                display: 'none', // lg以上では非表示にする
              },
            }}
          >
            <IconButton
              onClick={toggleDrawer(true)}
              sx={{
                color: 'white', // アイコンの色を指定
                padding: '0px 0px 0px 0px',
              }}
            >
              <HamburgerMenuSVG sx={{
                color: color,
                [theme.breakpoints.down('sm')]: {
                  width: '24px'
                },
                [theme.breakpoints.between('sm', 'lg')]: {
                  width: '50px'
                },
              }} />
            </IconButton>
            <SwipeableDrawer
              anchor="right"
              open={isOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              PaperProps={{
                sx: {
                  width: '100%', // 幅を指定
                  height: '100vw', // 高さを100%に指定
                  backgroundColor: 'white', // 背景色を設定
                },
              }}
            >
              <HamburgerMenu toggleDrawer={toggleDrawer} startSplash={startSplash} />
            </SwipeableDrawer>
          </Box>
        </Box>
      </Box >
    </Box >
  );
}
