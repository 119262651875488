import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import styles from "./ContactForm.module.css";

function ContactTypography({ children }) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  return (
    <Typography
      sx={{
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        fontFamily: i18n.language === "en" ? "Inter" : "Noto Sans JP",
        color: "#515D6A",
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
          lineHeight: "20px",
          marginBottom: "4px",
          paddingLeft: "1px",
        },
        [theme.breakpoints.between("sm", "lg")]: {
          fontSize: "16px",
          lineHeight: "25px",
          marginBottom: "3px",
          paddingLeft: "3px",
        },
        [theme.breakpoints.between("lg", "xl")]: {
          fontSize: "15px",
          lineHeight: "19px",
          marginBottom: "4px",
          paddingLeft: "3px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: "15px",
          lineHeight: "19px",
          marginBottom: "4px",
          paddingLeft: "3px",
        },
      }}
    >
      {children}
    </Typography>
  );
}

export default function ContactForm({ setPage }) {
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [email, setEmail] = useState('');
  // const [company, setCompany] = useState('');
  // const [title, setTitle] = useState('');
  // const [message, setMessage] = useState('');
  const { t, i18n } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  const [submitMessage, setSubmitMessage] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  // formvalidationようにInputs型としてまとめておく
  type Inputs = {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    title: string;
    message: string;
  };

  // formValidation用の変数の初期化
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isMobile = window.innerWidth <= 768;
  const normal_mobileSvg = "images/util/SendMessage_SP_Normal.svg";
  const normal_pcSvg = "images/util/SendMessage_PC_Normal.svg";

  const mouseOver_mobileSvg = "images/util/SendMessage_SP_MouseOver.svg";
  const mouseOver_pcSvg = "images/util/SendMessage_PC_MouseOver.svg";

  const onSubmit = async (e) => {
    console.log(e);
    // e.preventDefault();  // いらない
    const response = await fetch(
      "https://4g37jcdlr2.execute-api.ap-northeast-1.amazonaws.com/prod/contact",
      {
        method: "POST",
        body: JSON.stringify(e),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    if (response.status === 200) {
      setSubmitMessage("Thank you, your message has been sent");
      setIsFormDisabled(true); // Disable the form
    } else {
      setSubmitMessage(`Error: ${data.error}`);
    }
  };

  useEffect(() => {
    setPage("contact");
    // alert("hogehoge")
  }, []);

  return (
    // border表示と中央配置をするために、コンテナを二つ配置している。
    <div className={`${styles.body}`}>
      <div className={`${styles.container}`}>
        <div className={`${styles.titleContainer}`}>
          <Typography className={`${styles.title}`} component="h2" variant="h1">
            Contact Us
          </Typography>
        </div>
        <div className={`${styles.formContainer}`}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`${styles.dFlex} ${styles.flexColumn}`}
          >
            <div
              className={`${styles.formShortContainer}`}
              style={{
                flexDirection: i18n.language === "en" ? "row" : "row-reverse",
              }}
            >
              <div className={`${styles.formShort}`}>
                <ContactTypography>{t(`contact.name1`)}</ContactTypography>
                <input
                  type="text"
                  className={
                    !errors.firstName
                      ? `${styles.formInputText}`
                      : `${styles.formInputText} ${styles.formInvalid}`
                  }
                  style={{
                    fontFamily:
                      i18n.language === "en" ? "Inter" : "Noto Sans JP",
                  }}
                  // formValidation
                  {...register("firstName", {
                    required:
                      i18n.language === "en"
                        ? "This field is required"
                        : "必須項目です",
                  })}
                />
                {errors.firstName && (
                  <p
                    className={`${styles.errorMessage}`}
                    style={{
                      fontFamily:
                        i18n.language === "en" ? "Inter" : "Noto Sans JP",
                    }}
                  >
                    {errors.firstName.message}
                  </p>
                )}
              </div>
              <div className={`${styles.formShort}`}>
                <ContactTypography>{t(`contact.name2`)}</ContactTypography>
                <input
                  type="text"
                  className={
                    !errors.lastName
                      ? `${styles.formInputText}`
                      : `${styles.formInputText} ${styles.formInvalid}`
                  }
                  style={{
                    fontFamily:
                      i18n.language === "en" ? "Inter" : "Noto Sans JP",
                  }}
                  // formValidation
                  {...register("lastName", {
                    required:
                      i18n.language === "en"
                        ? "This field is required"
                        : "必須項目です",
                  })}
                />
                {errors.lastName && (
                  <p
                    className={`${styles.errorMessage}`}
                    style={{
                      fontFamily:
                        i18n.language === "en" ? "Inter" : "Noto Sans JP",
                    }}
                  >
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>
            <div className={`${styles.formLong}`}>
              <ContactTypography>{t(`contact.email`)}</ContactTypography>
              <input
                type="email"
                className={
                  !errors.email
                    ? `${styles.formInputText}`
                    : `${styles.formInputText} ${styles.formInvalid}`
                }
                style={{
                  fontFamily: i18n.language === "en" ? "Inter" : "Noto Sans JP",
                }}
                // formValidation
                {...register("email", {
                  required:
                    i18n.language === "en"
                      ? "This field is required"
                      : "必須項目です",
                })}
              />
              {errors.email && (
                <p
                  className={`${styles.errorMessage}`}
                  style={{
                    fontFamily:
                      i18n.language === "en" ? "Inter" : "Noto Sans JP",
                  }}
                >
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className={`${styles.formShortContainer}`}>
              <div className={`${styles.formShort}`}>
                <ContactTypography>{t(`contact.company`)}</ContactTypography>
                <input
                  type="text"
                  className={
                    !errors.company
                      ? `${styles.formInputText}`
                      : `${styles.formInputText} ${styles.formInvalid}`
                  }
                  style={{
                    fontFamily:
                      i18n.language === "en" ? "Inter" : "Noto Sans JP",
                  }}
                  // formValidation
                  {...register("company", {
                    required:
                      i18n.language === "en"
                        ? "This field is required"
                        : "必須項目です",
                  })}
                />
                {errors.company && (
                  <p
                    className={`${styles.errorMessage}`}
                    style={{
                      fontFamily:
                        i18n.language === "en" ? "Inter" : "Noto Sans JP",
                    }}
                  >
                    {errors.company.message}
                  </p>
                )}
              </div>
              <div className={`${styles.formShort}`}>
                <ContactTypography>{t(`contact.title`)}</ContactTypography>
                <input
                  type="text"
                  className={`${styles.formInputText}`}
                  style={{
                    fontFamily:
                      i18n.language === "en" ? "Inter" : "Noto Sans JP",
                  }}
                  {...register("title", {})}
                />
              </div>
            </div>
            <div className={`${styles.formMessage}`}>
              <ContactTypography>{t(`contact.message`)}</ContactTypography>
              <textarea
                className={
                  !errors.message
                    ? `${styles.formInputText} ${styles.textArea}`
                    : `${styles.formInputText} ${styles.textArea} ${styles.formInvalid}`
                }
                style={{
                  fontFamily: i18n.language === "en" ? "Inter" : "Noto Sans JP",
                }}
                // formValidation
                {...register("message", {
                  required:
                    i18n.language === "en"
                      ? "This field is required"
                      : "必須項目です",
                })}
              ></textarea>
              {errors.message && (
                <p
                  className={`${styles.errorMessage}`}
                  style={{
                    fontFamily:
                      i18n.language === "en" ? "Inter" : "Noto Sans JP",
                  }}
                >
                  {errors.message.message}
                </p>
              )}
            </div>
            {/* メッセージ送信ボタンが押される前 */}
            {!isFormDisabled && (
              <button
                className={`${styles.button}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                type="submit"
              >
                <Box>
                  <img
                    src={isMobile ? mouseOver_mobileSvg : mouseOver_pcSvg}
                    alt="Hovered Image"
                    className={`${styles.submitImg}`}
                    style={{
                      opacity: isHovered ? 1 : 0,
                      transition: "opacity 0.5s",
                    }}
                  />
                  <img
                    src={isMobile ? normal_mobileSvg : normal_pcSvg}
                    alt="Default Image"
                    className={`${styles.submitImg}`}
                    style={{
                      opacity: isHovered ? 0 : 1,
                      transition: "opacity 0.5s",
                    }}
                  />
                </Box>
              </button>
            )}
          </form>
          {submitMessage && (
            <div className={`${styles.submitMessage}`}>{submitMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
}
