import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Counter from '../util/Counter';


const OurBusinessCounter = ({ i, count }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div>
            <Typography component="h1" variant="h1"
                sx={{
                    alignItems: "stretch",
                    fontSize: "6.25vw",
                    letterSpacing: "-0.06em",
                    [theme.breakpoints.down('sm')]: {
                        fontSize: "51px",
                        letterSpacing: "-3.06px",
                        lineHeight: `calc(61 / 51)`
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        marginTop: '-7px',
                        fontSize: "67px",
                        letterSpacing: `calc(-60 * 1000 / 67px)`,
                        lineHeight: `1`
                    },
                    [theme.breakpoints.up('xl')]: {
                        fontSize: "120px",
                        letterSpacing: "-7.2px",
                        lineHeight: "0.8"
                    },
                }}
            >
                <Counter end={count} />
            </Typography>
            <Typography component="p" variant="body1"
                sx={{
                    textAlign: 'left',
                    fontFamily: 'Inter',
                    fontWeight: 'normal',
                    fontSize: '35px',
                    lineHeight: '60px',
                    letterSpacing: '-2.1px',
                    color: '#0D1D2F',
                    opacity: 1,
                    [theme.breakpoints.down('sm')]: {
                        fontSize: "18px",
                        letterSpacing: "-1.08px",
                        lineHeight: `calc(41 / 18)`
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        fontSize: "22px",
                        letterSpacing: `calc(-60 * 1000 / 22px)`,
                        lineHeight: `calc(41 / 22)`
                    }
                }}
            >
                {t(`ourBusiness.counter.${i}.subtitle`)}
            </Typography>
            <Typography component="p" variant="body2"
                sx={{
                    [theme.breakpoints.down('sm')]: {
                        fontSize: "11px",
                        letterSpacing: "0",
                        lineHeight: `calc(15 / 11)`
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        fontSize: "11px",
                        letterSpacing: "0",
                        lineHeight: `calc(16 / 11)`
                    },
                }}
            >
                {t(`ourBusiness.counter.${i}.body`)}
            </Typography>
        </div >
    );
};

export default OurBusinessCounter;
