import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import CollectAndDiagnose from '../component-parts/walccare/CollectAndDiagnose';
import Contact from '../component-parts/walccare/Contact';
import CustomerValue from '../component-parts/walccare/CustomerValue';
import ReportAndCare from '../component-parts/walccare/ReportAndCare';
import Scan from '../component-parts/walccare/Scan';
import ScrollTab from '../component-parts/walccare/ScrollTab';
import Vision from '../component-parts/walccare/Vision';
import WalcCareTheme from './WalcCareTheme';

export default function WalcCare({ setPage }) {

  const walccareTheme = WalcCareTheme()
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    setPage('walccare')
  }, []);


  const [activeTab, setActiveTab] = useState(0);

  const tabContents = [
    { tabLabelNumber: "01", tabLabelTitle: "Scan", id: 'scan' },
    { tabLabelNumber: "02", tabLabelTitle: "Collect and Diagnose", id: 'collecAndDiagnose' },
    { tabLabelNumber: "03", tabLabelTitle: "Report and Care", id: 'reportAndCare' },
  ];

  const handleTabChange = (tabIndex: number) => {
    // tabのアクティベーションとスクロールを制御する。
    setActiveTab(tabIndex);

    const contentId = tabContents[tabIndex].id;
    const element = document.getElementById(contentId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      return
    }

    // BUG: easeInOutQuartだとiOS16系でのみタブが一瞬消えるバグがある。
    // レンダリングの問題である可能性が高い。
    // scroller.scrollTo(contentId, {
    //   duration: 800,
    //   delay: 0,
    //   smooth: 'easeInOutQuart',
    // });
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '-50% 0% -50% 0%',
      threshold: 0.0,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const contentId = entry.target.id;
          const tabIndex = tabContents.findIndex((tab) => tab.id === contentId);
          setActiveTab(tabIndex);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    tabContents.forEach((tab) => {
      const contentElement = document.getElementById(tab.id);
      if (contentElement) {
        observer.observe(contentElement);
      }
    });

    // クリーンアップ関数
    return () => {
      observer.disconnect();
    };
  }, []);

  interface TabInfo {
    tabLabelNumber: string;
    tabLabelTitle: string;
    component: React.FunctionComponent;
  }

  const componentMap: TabInfo[] = [
    { tabLabelNumber: "01", tabLabelTitle: "Scan", component: Scan },
    { tabLabelNumber: "02", tabLabelTitle: "Collect and Diagnose", component: CollectAndDiagnose },
    { tabLabelNumber: "03", tabLabelTitle: "Report and Care", component: ReportAndCare },
  ]

  return (
    <React.Fragment>
      <ThemeProvider theme={walccareTheme()} >
        <Vision />
        <CustomerValue />
        {isDownSm ? (
          <React.Fragment>
            <ScrollTab tabContents={tabContents} handleTabChange={handleTabChange} activeTab={activeTab} />
            <Box id="scan"
              sx={{
                paddingTop: '50px'
              }}>
              < Scan />
            </Box>
            <Box id="collecAndDiagnose"
              sx={{
                paddingTop: '50px'
              }}>
              <CollectAndDiagnose />
            </Box>
            <Box id="reportAndCare"
              sx={{
                paddingTop: '50px'
              }}>
              <ReportAndCare />
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box id="scan">
              <ScrollTab tabContents={tabContents} handleTabChange={handleTabChange} activeTab={activeTab} />
              < Scan />
            </Box>
            <Box id="collecAndDiagnose">
              <ScrollTab tabContents={tabContents} handleTabChange={handleTabChange} activeTab={activeTab} />
              <CollectAndDiagnose />
            </Box>
            <Box id="reportAndCare">
              <ScrollTab tabContents={tabContents} handleTabChange={handleTabChange} activeTab={activeTab} />
              <ReportAndCare />
            </Box>
          </React.Fragment>
        )}
        <Contact />
      </ThemeProvider >
    </React.Fragment >
  );
}
