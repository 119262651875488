import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';


export function HoverLinkedButton({ imgBefore, imgAfter, link }) {
    const [isHovered, setIsHovered] = useState(false);
    const [isExternal, setIsExternal] = useState(false);
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setIsExternal(isLinkExternal(link));
    }, [link]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        if (isExternal) {
            window.open(link, '_blank'); // 新しいタブで外部サイトを開く
        } else {
            window.location.href = link; // 同じサイトの別のURLに遷移
        }
    };

    const isLinkExternal = (url) => {
        const currentDomain = window.location.hostname;
        const linkDomain = new URL(url, window.location.href).hostname;
        return currentDomain !== linkDomain;
    };


    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            style={{
                cursor: 'pointer',
            }}>
            {/* ゆっくり切り替わる */}
            <Box>
                <img
                    src={imgAfter}
                    // src="images/util/readmoreafter.svg"
                    alt="Hovered Image"
                    style={{
                        position: 'absolute',
                        opacity: isHovered ? 1 : 0,
                        transition: 'opacity 0.5s', // 0.5秒でゆっくりとオパシティを変更
                        height: isDownSm ? '39px' : '51px',
                    }}
                />
                <img
                    // src="images/util/readmorebefore.svg"
                    src={imgBefore}
                    alt="Default Image"
                    style={{
                        position: 'absolute',
                        opacity: isHovered ? 0 : 1,
                        transition: 'opacity 0.5s', // 0.5秒でゆっくりとオパシティを変更
                        height: isDownSm ? '39px' : '51px',
                    }}
                />
            </Box>
        </div >
    );
}

