import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseBoxWhite } from "../util/BaseBox";
import { HoverLinkedButton } from '../util/HoverLinkedButton';


export default function Contact() {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const lang = i18n.language.split('-')[0]; // ページの言語を取得（"en"または"ja"）
    const containerStyles = {
        padding: '91px 0px 245px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '62px 0px 145px 0px',
        }
    };
    const getFontFamily = (lang: string): string => {
        return lang === 'ja' ? 'Noto Sans JP' : 'Inter';
    };

    return (
        <BaseBoxWhite containerStyles={containerStyles}>
            <Typography
                textAlign='center'
                sx={{
                    fontFamily: getFontFamily(lang),
                    fontWeight: i18n.language === 'en' ? '400' : '400',
                    fontSize: i18n.language === 'en' ? '80px' : '40px',
                    // lineHeight: i18n.language === 'en' ? `calc(60 / 80)` : '1',
                    lineHeight: i18n.language === 'en' ? '1.1' : '1', // TODO: デザインカンプと異なるが、hotfix
                    letterSpacing: i18n.language === 'en' ? `calc(-60 / 1000 * 80px)` : `calc(-30 / 1000 * 40px)`,
                    color: "#484848",
                    paddingBottom: "64px",
                    [theme.breakpoints.down('sm')]: {
                        textAlign: i18n.language === 'en' ? 'center' : 'left',
                        fontWeight: '400',
                        fontSize: i18n.language === 'en' ? '30px' : '28px',
                        lineHeight: i18n.language === 'en' ? `calc(40 / 30)` : `calc(40 / 28)`,
                        letterSpacing: i18n.language === 'en' ? `calc(-30 / 1000 * 30px)` : `calc(-30 / 1000 * 28px)`,
                        paddingBottom: "71px",
                    },
                }}>
                {t('walccare.contact.body')}
            </Typography>
            <Box sx={{
                position: 'relative'
            }}>
                <Box sx={{
                    position: 'absolute',
                    left: "50%",
                    transform: 'translate(-59.46px, 0%)', // transform: 'translate(-50%, 0%)',
                    [theme.breakpoints.down('sm')]: {
                        transform: 'translate(-45.461px, 0%)', // transform: 'translate(-50%, 0%)',
                    },
                }}>
                    <HoverLinkedButton
                        imgBefore="/images/walccare/contactbefore.svg"
                        imgAfter="/images/walccare/contactafter.svg"
                        link='/contact'
                    />
                </Box>
            </Box>
        </BaseBoxWhite >
    );
}
