import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import React, { useEffect, useState } from 'react';
import { I18nextProvider, initReactI18next, useTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ContactForm from '../component-parts/contact/Contact';
import Splash from '../component-parts/util/Splash';
import "./App.css";
import Footer from './Footer';
import globalTheme from './GlobalTheme';
import Header from "./Header";
import NotFound from './NotFound';
import Policy from './Policy';
import Top from './Top';
import WalcCare from './WalcCare';


const sections = [
  { title: 'TOP', url: '/', imageUrl: 'images/header/about.png' },
  { title: 'WALC CARE', url: '/walccare', imageUrl: 'images/header/about.png', headerTitle: "walccare.header.title", headerMessage: "walccare.header.message" },
  { title: 'CONTACT', url: '/contact', imageUrl: '/images/header/business.png' },
  { title: 'POLICY', url: '/policy', imageUrl: '/images/header/career.png' }
];

const sectionsMap = new Map<string, Map<string, string | boolean>>([
  ["top", new Map<string, string | boolean>([
    ["title", 'TOP'],
    ["url", '/'],
    ["imageUrl", 'movies/toppage.mp4'],
    ["imageUrlMobile", 'movies/toppage.mp4'],
    ["headerTitle", ""],
    ["headerMessage", ""],
    ["isVideoBackground", true],
    ["isImageBackgroud", false],
    ["isNoBackground", false]]
  )],
  ["walccare", new Map<string, string | boolean>([
    ["title", 'WALC CARE'],
    ["url", '/walccare'],
    ["imageUrl", '/images/header/HP_WALCCARE_NTX500_B001.png'],
    ["imageUrlMobile", '/images/header/HP_WALCCARE_NTX500_B001_SP.png'],
    ["headerTitle", "walccare.header.title"],
    ["headerMessage", "walccare.header.body"],
    ["isVideoBackground", false],
    ["isImageBackgroud", true],
    ["isNoBackground", false]]
  )],
  ["contact", new Map<string, string | boolean>([
    ["title", 'CONTACT'],
    ["url", '/contact'],
    ["imageUrl", ''],
    ["imageUrlMobile", ''],
    ["headerTitle", ""],
    ["headerMessage", ""],
    ["isVideoBackground", false],
    ["isImageBackgroud", false],
    ["isNoBackground", true]]
  )],
  ["policy", new Map<string, string | boolean>([
    ["title", 'POLICY'],
    ["url", '/policy'],
    ["imageUrl", 'images/header/career.png'],
    ["imageUrlMobile", 'images/header/Policy_SP.png'],
    ["headerTitle", ""],
    ["headerMessage", ""],
    ["isVideoBackground", false],
    ["isImageBackgroud", true],
    ["isNoBackground", false]]
  )],
  ["notfound", new Map<string, string | boolean>([
    ["title", 'NOT FOUND'],
    ["url", '*'],
    ["imageUrl", ''],
    ["imageUrlMobile", ''],
    ["headerTitle", ""],
    ["headerMessage", ""],
    ["isVideoBackground", false],
    ["isImageBackgroud", false],
    ["isNoBackground", true]]
  )],
]);


i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ja'],
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: require('../locales/en/translation.json'),
      },
      ja: {
        translation: require('../locales/ja/translation.json'),
      },
    },
  });

function App() {
  const [page, setPage] = useState('top');
  const { t, i18n } = useTranslation();

  const [showSplash, setShowSplash] = useState(false);

  const startSplash = () => {
    setShowSplash(true)
    setTimeout(() => {
      setShowSplash(false);
      setstartHeaderVideo(prevState => prevState + 1) // 動画を最初から再生する
    }, 600); // 表示時間が600msecであれば、ストレスと感じない
  };

  useEffect(() => {
    // session storageを使って初回訪問時はスプラッシュ画面を表示
    splashInitialVision()
  }, []);

  const splashInitialVision = () => {
    if (sessionStorage.getItem("visited") === null) {
      startSplash();
      // sessionStorageに訪問済みとしてマーク
      sessionStorage.setItem("visited", "true");
    }
  }

  const [startHeaderVideo, setstartHeaderVideo] = useState(0);


  return (
    <I18nextProvider i18n={i18n}>
      <Splash showSplash={showSplash} />
      <ThemeProvider theme={
        globalTheme()
      } >
        <CssBaseline />
        <React.Fragment>
          < Header
            sections={sections}
            sectionsMap={sectionsMap}
            page={page}
            startSplash={startSplash}
            startHeaderVideo={startHeaderVideo}
          />
          <Router>
            <Routes>
              <Route path="/" element={<Top setPage={setPage} startSplash={startSplash} />} />
              <Route path="/policy" element={<Policy setPage={setPage} />} />
              <Route path="/walccare" element={<WalcCare setPage={setPage} />} />
              <Route path="/contact" element={<ContactForm setPage={setPage} />} />
              {/* 他のルートとマッチしない場合はNotFoundを表示 */}
              <Route path="*" element={<NotFound setPage={setPage} />} />
            </Routes>
          </Router>
          < Footer />
        </React.Fragment>
      </ThemeProvider >
    </I18nextProvider >
  );
}

export default App;
