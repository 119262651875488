import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { BottomLineBox } from "../util/BaseBox";
import FadeInHoverClipImage from '../util/FadeInHoverClipImage';


const TypograhpyFeaturedProject = ({ i }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{
            paddingX: '20px',
            [theme.breakpoints.down('sm')]: {
                paddingX: '0px',
            },
            [theme.breakpoints.between('sm', 'lg')]: {
                paddingX: '17px',
            },
        }}>
            <Typography component="p" variant="body1"
                sx={{
                    textAlign: 'left',
                    fontFamily: 'Inter',
                    fontWeight: 'normal',
                    fontSize: '35px',
                    lineHeight: '60px',
                    letterSpacing: '-2.1px',
                    color: '#0D1D2F',
                    opacity: 1,
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '24px',
                        letterSpacing: `calc(-60 / 1000 * 24px)`, // 単位 px を追加
                        lineHeight: `calc(29 / 24)`,
                        padding: '18px 0 4px 0'
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        fontSize: '22px',
                        letterSpacing: `calc(-60 / 1000 * 22px)`, // 単位 px を追加
                        lineHeight: `calc(38 / 22)`,
                        padding: '13px 0 9px 0'
                    },
                }}>
                {t(`featuredProjects.projects.${i}.project`)}
            </Typography>
            <Typography
                component="p"
                variant={isDownSm ? 'body1' : 'body2'} // sm以下ではbody1にする。
                sx={{
                    [theme.breakpoints.between('sm', 'lg')]: {
                        fontSize: '12px',
                    },
                }}
            >
                {t(`featuredProjects.projects.${i}.body`)}
            </Typography>
        </Box >
    )
}


export default function FeaturedProjects() {
    const theme = useTheme();
    const { t } = useTranslation();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
    const betweenSmLg = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
    const borderedItemStyle = {
        position: 'relative' as 'relative',
    };
    const borderStyle = {
        position: 'absolute' as 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: '1px',
        background: isDownSm ? 'none' : 'rgba(72, 72, 72, 0.6)', // ブレイクポイントによってborderをなしに。
        content: '""',
    };
    const borderStyleLeft = {
        position: 'absolute' as 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '1px',
        background: isDownSm ? 'none' : 'rgba(72, 72, 72, 0.6)', // ブレイクポイントによってborderをなしに。
        content: '""',
    };
    function getGridGap(): number {
        if (isDownSm) {
            return 70 / 8;
        } else if (betweenSmLg) {
            return 0;
        } else {
            return 0;
        }
    }

    return (
        <div>
            <BottomLineBox
                sx={{
                    paddingBottom: '140.1px',
                    [theme.breakpoints.down('sm')]: {
                        paddingBottom: '69.56px',
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        paddingBottom: '73px',
                    },
                }}
            >
                <Box>
                    <Box sx={{
                        paddingBottom: '50px',
                        [theme.breakpoints.down('sm')]: {
                            paddingBottom: '8.27px',
                        },
                        [theme.breakpoints.between('sm', 'lg')]: {
                            paddingBottom: '13.6px',
                        },
                    }}>
                        <Typography component="h1" variant="h1"
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    lineHeight: '1.1' // 他のh1と同じ設定だと'j'下の画像に重なってしまう。
                                },
                            }}
                        >
                            {t('featuredProjects.title')}
                        </Typography>
                    </Box>
                    <Grid container
                        spacing={getGridGap()}
                        sx={{
                            borderLeft: '1px solid rgba(72, 72, 72, 0.6)',
                            [theme.breakpoints.down('sm')]: {
                                borderLeft: 'none',
                            },
                            [theme.breakpoints.between('sm', "lg")]: {
                                borderLeft: 'none',
                            },
                        }}
                    >
                        <Grid item xs={12} sm={6} lg={4} sx={borderedItemStyle} >
                            {/* sm-lgでは2段組み担った際に、左側のborderが必要*/}
                            {betweenSmLg ? (
                                <span style={borderStyleLeft} />
                            ) : (
                                <Box display="none"></Box>
                            )
                            }
                            <FadeInHoverClipImage
                                src="images/top/walccare.png"
                                alt='image'
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    verticalAlign: 'bottom'
                                }}
                            />
                            <span style={borderStyle} />
                            <TypograhpyFeaturedProject i={0} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} sx={borderedItemStyle} >
                            <FadeInHoverClipImage
                                src="images/top/amrrobot.png"
                                alt='image'
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    verticalAlign: 'bottom'
                                }}
                            />
                            <span style={borderStyle} />
                            <TypograhpyFeaturedProject i={1} />
                        </Grid>
                        {/* sm-lgでは2段組みになるが、spacingの調整を行う。 */}
                        <Box display="none" sx={{
                            [theme.breakpoints.between('sm', 'lg')]: {
                                display: 'block',
                                height: 40,
                                width: '100%',
                            },
                        }}></Box>
                        <Grid item xs={12} sm={6} lg={4} sx={borderedItemStyle} >
                            {/* sm-lgでは2段組み担った際に、左側のborderが必要*/}
                            {betweenSmLg ? (
                                <span style={borderStyleLeft} />
                            ) : (
                                <Box display="none"></Box>
                            )
                            }
                            <FadeInHoverClipImage
                                src="images/top/humanhealthcare.png"
                                alt='image'
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    verticalAlign: 'bottom'
                                }}
                            />
                            <span style={borderStyle} />
                            <TypograhpyFeaturedProject i={2} />
                        </Grid>
                    </Grid>
                </Box>
            </BottomLineBox>
        </div >
    );
}
