import { TabContext, TabList } from '@mui/lab';
import { Box, Tab, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { BaseBox } from "../util/BaseBox";


const BorderBottomTabList = styled(TabList)({
    selectedTab: {
        borderBottom: '2px solid #000000',
    },
    '& .MuiTabs-indicator': {
        borderBottom: '3px solid #000000',
        backgroundColor: '#0D1D2F',
    },
},
);

const BorderBottomTab = styled(Tab)({
    textTransform: 'none',
    alignItems: 'flex-start',
    width: '33.3333%',
    borderBottom: '1px solid rgba(112, 112, 112, 0.6)',
    '&.Mui-selected': {
        color: '#0D1D2F',
        opacity: 1
    },
    '&:hover': {
        color: '#0D1D2F',
        opacity: 1,
        borderBottom: '1px solid rgba(13, 29, 47, 1)',
    },
    padding: '14px 0 13px 0',
    fontFamily: 'Inter',
    fontSize: "16px",
    fontWeight: "400",
    letterSpacing: `calc(-60 / 1000 * 16px)`,
    color: "#707070",
    opacity: 0.75,
},
);

function tabLabel(tabLabelNumber, tabLabelTitle, theme) {

    return (
        <Box sx={{
            textAlign: 'left',
            justifyContent: 'start'
        }}>
            <Box
                component='span'
                sx={{
                    fontFamily: 'Inter',
                    fontSize: '36px',
                    color: '#515D6A',
                    lineHeight: 1,
                    display: 'block',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '20px',
                    }
                }}
            >
                {tabLabelNumber}
            </Box>
            <Box
                component='span'
                sx={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    color: '#515D6A',
                    lineHeight: 1,
                    display: 'block',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '10px',
                    }
                }}
            >
                {tabLabelTitle}
            </Box>
        </Box >
    )
}

export default function ScrollTab({ tabContents, handleTabChange, activeTab }) {
    const theme = useTheme()
    const [isSticky, setIsSticky] = React.useState(false);
    const headerRef = React.useRef<HTMLDivElement>(null);
    const [headerOffsetTop, setHeaderOffsetTop] = React.useState<number | null>(null); // スクロールタブの上辺の位置

    React.useEffect(() => {
        if (headerRef.current) {
            setHeaderOffsetTop(headerRef.current.offsetTop);
        }
    }, []);

    React.useEffect(() => {
        const handleScroll = () => {

            const windowScrollTop = window.scrollY; // windowの上辺の位置

            if (headerOffsetTop === null) return;

            // タブが画面上にstickyに張り付く
            if (windowScrollTop >= headerOffsetTop) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerOffsetTop]);

    return (
        <React.Fragment>
            <BaseBox
                ref={headerRef}
                sx={{
                    [theme.breakpoints.down('sm')]: {
                        position: isSticky ? 'sticky' : 'relative',
                        top: 0,
                        width: '100%',
                        pointerEvents: 'auto',
                        zIndex: 1000,
                        backgroundColor: '#EAF6F7',
                    }
                }}>
                <Box
                    sx={{
                        position: "static",
                        paddingTop: '116px', // scrollするときの位置を調整
                        [theme.breakpoints.down('sm')]: {
                            padding: '0px 0px 0px 0px'
                        }
                    }}>
                    <TabContext value={activeTab}>
                        <BorderBottomTabList
                            onChange={(event, newValue) => handleTabChange(newValue)}
                            aria-label="lab API tabs example"
                            variant="fullWidth"
                        >
                            {tabContents.map((tab, index: number) => (
                                <BorderBottomTab
                                    key={index}
                                    label={tabLabel(tab.tabLabelNumber, tab.tabLabelTitle, theme)}
                                    sx={{
                                        [theme.breakpoints.down('sm')]: {
                                            padding: '14px 0px 7px 0px'
                                        }
                                    }} />
                            ))}
                        </BorderBottomTabList>
                    </TabContext>
                </Box>
            </BaseBox>
        </React.Fragment >
    )
}