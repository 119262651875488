import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { BaseBox } from "../util/BaseBox";
import { BorderBottomTypography } from '../util/BorderBottomTypography';
import { ImageViewer, ImageViewerBottom } from './ImageView';
import { ScanMainImageSP } from './ScanMainImage';
import WalccareIcon from './WalccareIcon';


export default function Scan() {
    const theme = useTheme();
    const { t } = useTranslation();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
    const mainImagePath = "/images/walccare/WALCCARE_scan.svg";

    return (
        <BaseBox
            sx={{
                padding: "119px 0px 100px 0px",
                [theme.breakpoints.down('sm')]: {
                    paddingTop: "46px",
                    paddingBottom: '0px',
                }
            }}
        >
            <BorderBottomTypography containerStyles={{}}>
                {t('walccare.scan.title')}
            </BorderBottomTypography>
            {isDownSm ? (
                <Box >
                    <Box display='flex' paddingTop='28px'>
                        <Box sx={{
                            width: "15px",
                            backgroundColor: 'rgba(167, 176, 177, 0.13)',
                            borderTopLeftRadius: '15px'
                        }}></Box>
                        < ImageViewer id='scanImage' scrollPosition='start'>
                            <ScanMainImageSP sx={{
                                height: '100%',
                                width: 'auto',
                                maxWidth: 'none',
                                verticalAlign: 'bottom',
                            }} />
                        </ ImageViewer>
                        <Box sx={{
                            width: "15px",
                            backgroundColor: 'rgba(167, 176, 177, 0.13)',
                            borderTopRightRadius: '15px'
                        }}></Box>
                    </Box>
                    < ImageViewerBottom>
                    </ImageViewerBottom>
                </Box>
            ) : (
                <Box display="none"></Box>
            )
            }
            <Typography
                component="h2"
                variant="h2"
                sx={{
                    padding: "20px 0px 30px 0",
                    [theme.breakpoints.down('sm')]: {
                        padding: "20px 0px 20px 0",
                    }
                }}
            >
                {t('walccare.scan.subtitle')}
            </Typography>
            <Typography
                component="p"
                variant="body1"
                sx={{
                    paddingBottom: "27px",
                    [theme.breakpoints.down('sm')]: {
                        paddingBottom: "62px"
                    }
                }}
            >
                {t('walccare.scan.body')}
            </Typography>
            {
                !isDownSm ? (
                    <img src={mainImagePath} alt="Description"
                        style={{
                            width: '100%',
                            height: 'auto',
                            paddingBottom: '69px',
                        }} />
                ) : (<Box display="none"></Box>)
            }
            <Grid container
                rowSpacing={{ xs: 38 / 8, lg: 87 / 8 }}
                columnSpacing={{ lg: 110 / 8 }}
            >
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.scan.icons'} i={0} img={"/images/walccare/Icon_01_01.svg"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.scan.icons'} i={1} img={"/images/walccare/Icon_01_03.svg"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.scan.icons'} i={2} img={"/images/walccare/Icon_01_02.svg"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <WalccareIcon t={t} textPath={'walccare.scan.icons'} i={3} img={"/images/walccare/Icon_01_04.svg"} />
                </Grid>
            </Grid>
        </BaseBox >
    )
}
