import { Box, Button, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function BaseLanguageButton({ changeLanguage, selectedLanguage, language, text, color, containerStyles }) {
    const theme = useTheme()
    return (
        <Button
            disabled={selectedLanguage == language}
            onClick={() => changeLanguage(language)}
            sx={{
                padding: '0px 0px 0px 0px',
                minWidth: '0px',
                opacity: selectedLanguage === language ? 1 : 0.5,
                ...containerStyles,
            }}
            disableRipple  // リップルエフェクトを無効化
        >
            <Typography
                sx={{
                    textAlign: 'left',
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: `calc(26 / 14)`,
                    letterSpacing: '0px',
                    color: color,
                    opacity: selectedLanguage === language ? 1 : 0.5,
                    padding: '0px 0px 0px 0px',
                    [theme.breakpoints.down('sm')]: {
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        letterSpacing: '-1.2px',
                        lineHeight: '1',
                        color: '#484848'
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        fontSize: '39px',
                        fontWeight: '400',
                        letterSpacing: '-1.2px',
                        lineHeight: '1',
                        color: '#484848'
                    }
                }}>
                {text}
            </Typography>
        </Button>
    )
}

function LanguageButton({ startSplash, color }) {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const theme = useTheme()

    const changeLanguage = (language: string) => {
        // プロミスなので、非同期に行われる。
        i18n.changeLanguage(language)
            .then(() => {
                setSelectedLanguage(language);
            })
            .then(() => {
                // スプラッシュ画面を表示した後に、動画を0秒から再生する
                startSplash()
            })
    };

    return (
        <Box
            height={48}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    height: '28px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '18vw'
                },
                [theme.breakpoints.between('sm', 'lg')]: {
                    height: '39px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '18vw'
                }
            }}
        >
            <BaseLanguageButton
                changeLanguage={changeLanguage}
                selectedLanguage={selectedLanguage}
                language={'en'}
                text={"EN"}
                color={color}
                containerStyles={{
                    padding: '0px 16.5px 0px 0px',
                    [theme.breakpoints.down('sm')]: {
                        padding: '0px 27px 0px 0px',
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        padding: '0px 39px 0px 0px',
                    }
                }}
            />
            <BaseLanguageButton
                changeLanguage={changeLanguage}
                selectedLanguage={selectedLanguage}
                language={'ja'}
                text={"JP"}
                color={color}
                containerStyles={{ padding: '0px 0px 0px 0px', }}
            />
        </Box >
    );
}

export default LanguageButton;
