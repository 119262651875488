import { Box, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { BaseBoxWhite } from "../util/BaseBox";
import { BorderBottomTypography } from '../util/BorderBottomTypography';


export default function Vision() {
    const theme = useTheme()
    const isDownLg = useMediaQuery(theme.breakpoints.down('lg'))
    const containerStyles = {
        padding: '85.5px 0px 198px 0px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '47.3px',
            paddingBottom: '90.2px',
        }
    };

    return (
        <React.Fragment>
            <BaseBoxWhite containerStyles={containerStyles}>
                <BorderBottomTypography containerStyles={{}}>
                    Vision
                </BorderBottomTypography>
                <Box
                    textAlign='center'
                    sx={{
                        height: 'auto',
                        paddingTop: '83px',
                        [theme.breakpoints.down('sm')]: {
                            paddingTop: '25px'
                        }
                    }}>
                    {isDownLg ? (
                        <img
                            src="/images/walccare/WALC CARE diagram_SP.png"
                            alt="WACL CARE concept"
                            style={{
                                width: '100%',
                                aspectRatio: (1352 / 1968),
                                verticalAlign: 'bottom'
                            }} />) : (
                        <Grid container
                            rowSpacing={0}
                            columnSpacing={{
                            }}
                            alignItems='center'
                            justifyContent="flex-start"
                        >
                            <Grid item lg={4.2}>
                                <Box
                                    display='block'
                                    component="img"
                                    width={'100%'}
                                    maxWidth={347.43}
                                    sx={{
                                        verticalAlign: 'bottom',
                                    }}
                                    alt='WALC CARE logo and message'
                                    src="/images/walccare/WALC CARE Logo.svg"
                                />
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontWeight: '400',
                                        fontSize: '20px',
                                        letterSpacing: '-30 / 1000 * 20px',
                                        textAlign: 'left',
                                        padding: '23px 0px 0px 0px',
                                    }}>
                                    The platform for fully automizing your factory
                                </Typography>
                            </Grid>
                            <Grid item lg={7.8}
                                textAlign='right'
                            >
                                <Box
                                    component="img"
                                    width='100%'
                                    height='auto'
                                    maxWidth={952}
                                    sx={{
                                        verticalAlign: 'bottom',
                                    }}
                                    alt='WALC CARE concept'
                                    src="/images/walccare/WALC CARE diagram_PC.png"
                                />
                            </Grid>
                        </Grid>
                    )
                    }
                </Box >
            </BaseBoxWhite >
        </React.Fragment>
    );
}
