import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { BaseBox } from "../util/BaseBox";
import OurBusinessCounter from './OurBusinessCounter';

// TODO: 1920以上でのborderの色とalphaが違うかも。
export default function OurBusiness() {

    const { t } = useTranslation();
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const betweenSmLg = useMediaQuery(theme.breakpoints.between('sm', "lg"));
    function getGridGap(): number {
        // muiのspacingは1で8px
        if (isDownSm) {
            return 46 / 8;
        } else if (betweenSmLg) {
            return 24 / 8;
        } else {
            return 72.5 / 8;
        }
    }

    return (
        <div>
            <BaseBox
                sx={{
                    paddingTop: '150.5px',
                    paddingBottom: '129.5px',
                    [theme.breakpoints.down('sm')]: {
                        paddingTop: '69.4px',
                        paddingBottom: '69.7px'
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        paddingTop: '72.5px',
                        paddingBottom: '73px'
                    },
                }}>
                <Grid container
                    spacing={getGridGap()}
                >
                    {/* 左側 */}
                    <Grid item sm={12 * 320 / (320 + 340)} lg={12 * 752 / (752 + 695.5)}
                    >
                        <Box textAlign="left">
                            <Typography component="h1" variant="h1"
                                sx={{
                                    paddingBottom: "25px",
                                    [theme.breakpoints.down('sm')]: {
                                        paddingBottom: '10.7px'
                                    },
                                    [theme.breakpoints.between('sm', 'lg')]: {
                                        paddingBottom: '12px'
                                    },
                                }}
                            >
                                {t('ourBusiness.title')}
                            </Typography>
                            <Typography component="h2" variant="h2"
                                sx={{
                                    paddingBottom: "18px",
                                    [theme.breakpoints.down('sm')]: {
                                        paddingBottom: '11.7px'
                                    },
                                    [theme.breakpoints.between('sm', 'lg')]: {
                                        paddingBottom: '12px'
                                    },
                                }}
                            >
                                {t('ourBusiness.subtitle')}
                            </Typography>
                            <Typography component="p" variant="body1">
                                {t('ourBusiness.body')}
                            </Typography>
                        </Box>
                    </Grid>

                    {/* 右側 */}
                    <Grid item sm={12 * 340 / (320 + 340)} lg={12 * 695.5 / (752 + 695.5)}>
                        <Grid container
                            rowGap={{ sm: 40 / 8, lg: 12.5 }}
                            columnSpacing={0}
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    rowGap: 3.375,
                                },
                            }}
                        >
                            {/* 上段 */}
                            <Grid item xs={6} lg={6}
                                sx={{
                                    padding: "0 38px 0 38px",
                                    borderRight: '1px solid rgba(112, 112, 112, 0.6)',
                                    borderLeft: '1px solid rgba(112, 112, 112, 0.6)',
                                    [theme.breakpoints.down('sm')]: {
                                        padding: '0px 10px 0px 13.2px',
                                        borderRight: 'none',
                                        borderLeft: '1px solid rgba(72, 72, 72, 0.2)',
                                    },
                                    [theme.breakpoints.between('sm', 'lg')]: {
                                        padding: "0px 19px 0px 14px",
                                    },
                                }}
                            >
                                <OurBusinessCounter i={0} count={244} />
                            </Grid>
                            <Grid item xs={6} lg={6}
                                sx={{
                                    borderRight: '1px solid rgba(112, 112, 112, 0.6)',
                                    padding: "0 38px 0 38px",
                                    [theme.breakpoints.down('sm')]: {
                                        padding: '0px 10px 0px 13.2px',
                                        borderRight: 'none',
                                        borderLeft: '1px solid rgba(72, 72, 72, 0.2)',
                                    },
                                    [theme.breakpoints.between('sm', 'lg')]: {
                                        padding: "0px 19px 0px 14px",
                                    },
                                }}
                            >
                                <OurBusinessCounter i={1} count={18} />
                            </Grid>
                            {/* 下段 */}
                            <Grid item xs={6} lg={6}
                                sx={{
                                    padding: "0 38px 0 38px",
                                    borderRight: '1px solid rgba(112, 112, 112, 0.6)',
                                    borderLeft: '1px solid rgba(112, 112, 112, 0.6)',
                                    [theme.breakpoints.down('sm')]: {
                                        padding: '0px 10px 0px 13.2px',
                                        borderRight: 'none',
                                        borderLeft: '1px solid rgba(72, 72, 72, 0.2)',
                                    },
                                    [theme.breakpoints.between('sm', 'lg')]: {
                                        padding: "0px 19px 0px 14px",
                                    },
                                }}
                            >
                                <OurBusinessCounter i={2} count={16} />
                            </Grid>
                            <Grid item xs={6} lg={6}
                                sx={{
                                    borderRight: '1px solid rgba(112, 112, 112, 0.6)',
                                    padding: "0 38px 0 38px",
                                    [theme.breakpoints.down('sm')]: {
                                        padding: '0px 10px 0px 13.2px',
                                        borderRight: 'none',
                                        borderLeft: '1px solid rgba(72, 72, 72, 0.2)',
                                    },
                                    [theme.breakpoints.between('sm', 'lg')]: {
                                        padding: "0px 19px 0px 14px",
                                    },
                                }}
                            >
                                <OurBusinessCounter i={3} count={44} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </BaseBox>
        </div >
    );
}
