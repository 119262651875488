import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BottomLineBox } from "../util/BaseBox";


const CenteredTabList = styled(TabList)({
    selectedTab: {
        borderBottom: '3px solid #000000',
    },
    '& .MuiTabs-indicator': {
        borderBottom: '3px solid #000000',
        backgroundColor: '#0D1D2F', // 選択されているborderBottomの色が変わる
    },
},
);

const CenteredTab = styled(Tab)({
    textTransform: 'none',
    borderBottom: '1px solid rgba(112, 112, 112, 0.6)',  // 選択されていない、borderBottom
    '&.Mui-selected': {
        color: '#0D1D2F', // 選択されている文字の色
        opacity: 1
    },
    '&:hover': {
        color: '#0D1D2F',
        opacity: 1,
        borderBottom: '1px solid rgba(13, 29, 47, 1)',
    },
    padding: '16px 0 16px 0',
    fontFamily: 'Inter',
    fontSize: "15px",
    fontWeight: "400",
    letterSpacing: '-0.9px',
    color: "#707070",
    opacity: 0.75
},
);

const MvvSmartPhone = ({ t, theme, mvv }) => {
    return (
        <Box>
            <Box
                sx={{
                    textAlign: 'right',
                    borderBottom: '1px solid rgba(72, 72, 72, 0.2)',
                    paddingBottom: '5px',
                }}
            >
                <Typography
                    component="p"
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            fontFamily: 'Inter',
                            fontSize: '11px',
                            fontWeight: '400',
                            letterSpacing: '-0.66px',
                            lineHeight: `calc(14 / 11)`,
                        },
                    }}
                >
                    {t(`${mvv}.title`)}
                </Typography>
            </Box>
            <img
                src={`images/top/${mvv}.png`}
                alt="image"
                style={{
                    width: '100%',
                    height: 'auto',
                    aspectRatio: '760 / 534', // 画像のアスペクト比を指定
                    objectFit: 'cover',
                    objectPosition: "100 100",
                    verticalAlign: "top", // 画像の下の余白削除
                    paddingTop: "14px"
                }} />
            <Typography component="h1" variant="h1"
                sx={{
                    paddingTop: "46px",
                    paddingBottom: "10.68px"
                }}
            >
                {t(`${mvv}.title`)}
            </Typography>
            <Typography component="h2" variant="h2"
                sx={{ paddingBottom: "12px" }}>
                {t(`${mvv}.subtitle`)}
            </Typography>
            <Typography component="p" variant="body1"
                sx={{ paddingBottom: "70px" }}
            >
                {t(`${mvv}.body`)}
            </Typography>
        </Box >
    )
}

export default function Mvv() {
    const [value, setValue] = React.useState("1");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        < BottomLineBox
            sx={{
                paddingTop: '64px',  // tabの文字列のpaddingTop分を差し引いています。
                paddingBottom: '139px',
                [theme.breakpoints.down('sm')]: {
                    paddingTop: '51px',
                    paddingBottom: '0px'
                },
                [theme.breakpoints.between('sm', 'lg')]: {
                    paddingTop: '56px',
                    paddingBottom: "73px"
                },
            }
            }>
            <Box>
                {isDownSm ? (
                    <Box>
                        <MvvSmartPhone t={t} theme={theme} mvv={'vision'} />
                        <MvvSmartPhone t={t} theme={theme} mvv={'mission'} />
                        <MvvSmartPhone t={t} theme={theme} mvv={'value'} />
                    </Box>
                ) : (
                    < div >
                        <TabContext value={value}>
                            <Box sx={{
                                paddingBottom: '129px',
                                [theme.breakpoints.between('sm', 'lg')]: {
                                    paddingBottom: "74px"
                                },
                            }}>
                                <CenteredTabList onChange={handleChange} aria-label="lab API tabs example" centered variant="fullWidth">
                                    <CenteredTab label="Vision" value="1" />
                                    <CenteredTab label="Mission" value="2" />
                                    <CenteredTab label="Value" value="3" />
                                </CenteredTabList>
                            </Box>

                            {/* Vision */}
                            <TabPanel value="1" sx={{ padding: "0 0 0 0" }}>
                                <Grid container rowSpacing={{ sm: 2, lg: 0 }} columnSpacing={{ sm: 31 / 8, lg: 13.125 }}>
                                    <Grid item lg={5.5} sm={5.5}>
                                        <Box textAlign="left">
                                            <Typography component="h1" variant="h1"
                                                sx={{
                                                    paddingBottom: "25px",
                                                    [theme.breakpoints.between('sm', 'lg')]: {
                                                        paddingBottom: "12px"
                                                    },
                                                }}
                                            >
                                                {t('vision.title')}
                                            </Typography>
                                            <Typography component="h2" variant="h2"
                                                sx={{
                                                    paddingBottom: "18px",
                                                    [theme.breakpoints.between('sm', 'lg')]: {
                                                        paddingBottom: "12px"
                                                    },
                                                }}>
                                                {t('vision.subtitle')}
                                            </Typography>
                                            <Typography component="p" variant="body1">
                                                {t('vision.body')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6.5} sm={6.5}>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                            }}
                                        >
                                            <img
                                                src="images/top/vision.png"
                                                alt="image"
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    aspectRatio: '760 / 534', // 画像のアスペクト比を指定
                                                    objectFit: 'cover',
                                                    objectPosition: "100 100",
                                                    verticalAlign: "top", // 画像の下の余白削除
                                                }} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            {/* Mission */}
                            <TabPanel value="2" sx={{ padding: "0 0 0 0" }}>
                                <Grid container rowSpacing={{ sm: 2, lg: 0 }} columnSpacing={{ sm: 31 / 8, lg: 13.125 }}>
                                    <Grid item lg={5.5} sm={5.5}>
                                        <Box textAlign="left">
                                            <Typography component="h1" variant="h1"
                                                sx={{
                                                    paddingBottom: "25px",
                                                    [theme.breakpoints.between('sm', 'lg')]: {
                                                        paddingBottom: "12px"
                                                    },
                                                }}>
                                                {t('mission.title')}
                                            </Typography>
                                            <Typography component="h2" variant="h2"
                                                sx={{
                                                    paddingBottom: "18px",
                                                    [theme.breakpoints.between('sm', 'lg')]: {
                                                        paddingBottom: "12px"
                                                    },
                                                }}>
                                                {t('mission.subtitle')}
                                            </Typography>
                                            <Typography component="p" variant="body1">
                                                {t('mission.body')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={5.5} sm={6.5}>
                                        <img src="images/top/mission.png"
                                            alt="image"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                aspectRatio: '760 / 534', // 画像のアスペクト比を指定
                                                objectFit: 'cover',
                                                objectPosition: "100 100",
                                                verticalAlign: "top", // 画像の下の余白削除
                                            }} />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            {/* Value */}
                            <TabPanel value="3" sx={{ padding: "0 0 0 0" }}>
                                <Grid container rowSpacing={{ sm: 2, lg: 0 }} columnSpacing={{ sm: 31 / 8, lg: 13.125 }}>
                                    <Grid item lg={5.5} sm={5.5}>
                                        <Box textAlign="left">
                                            <Typography component="h1" variant="h1"
                                                sx={{ paddingBottom: "25px" }}>
                                                {t('value.title')}
                                            </Typography>
                                            <Typography component="h2" variant="h2"
                                                sx={{ paddingBottom: "18px" }}>
                                                {t('value.subtitle')}
                                            </Typography>
                                            <Typography component="p" variant="body1">
                                                {t('value.body')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={5.5} sm={6.5}>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                            }}
                                        >
                                            <img
                                                src="images/top/value.png"
                                                alt="image"
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    aspectRatio: '760 / 534', // 画像のアスペクト比を指定
                                                    objectFit: 'cover', // トリミング
                                                    objectPosition: "100 100",
                                                    verticalAlign: "top", // 画像の下の余白削除
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </div >
                )}
            </Box >
        </BottomLineBox >
    );
}
