import * as React from 'react';
import Access from '../component-parts/top/Access';
import Career from '../component-parts/top/Career';
import FeaturedProjects from '../component-parts/top/FeaturedProjects';
import Mvv from '../component-parts/top/Mvv';
import OurBusiness from '../component-parts/top/OurBusiness';
import OurCulture from '../component-parts/top/OurCulture';
import "./App.css";


export default function Top({ setPage, startSplash }) {

  React.useEffect(() => {
    setPage('top')
    startSplash()
  }, []);

  return (
    <React.Fragment>
      < Mvv />
      < OurBusiness />
      < FeaturedProjects />
      < OurCulture />
      < Career />
      < Access />
    </React.Fragment>
  );
}
