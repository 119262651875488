
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';


const HamburgerMenuSVG: React.FC<SvgIconProps> = (props) => {
    return (
        <React.Fragment>
            <SvgIcon
                {...props}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="14"
                    viewBox="0 0 33 14"
                >
                    <g id="Group_386" data-name="Group 386" transform="translate(0 1)">
                        <line id="Line_430" data-name="Line 430" x2="33" transform="translate(0 0)" fill="none" stroke="currentColor" stroke-width="2" />
                        <line id="Line_431" data-name="Line 431" x2="33" transform="translate(0 6)" fill="none" stroke="currentColor" stroke-width="2" />
                        <line id="Line_432" data-name="Line 432" x2="33" transform="translate(0 12)" fill="none" stroke="currentColor" stroke-width="2" />
                    </g>
                </svg>

            </SvgIcon>
        </React.Fragment>
    )
}

export default HamburgerMenuSVG;