import { createTheme, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


export default function WalcCareTheme() {
    const { t, i18n } = useTranslation()
    const lang = i18n.language.split('-')[0]

    const getFontFamily = (lang: string): string => {
        return lang === 'ja' ? 'Noto Sans JP' : 'Inter';
    };
    const theme = useTheme();
    const walccareTheme = () => createTheme({
        ...theme,
        typography: {
            h1: {
                ...theme.typography.h1,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '28px',
                    letterSpacing: `calc(-60 / 1000 * 28px)`,
                },
            },
            h2: {
                fontFamily: getFontFamily(lang),
                fontWeight: i18n.language === 'en' ? '400' : '500',
                fontSize: i18n.language === 'en' ? '45px' : '40px',
                lineHeight: i18n.language === 'en' ? `calc(60 / 45)` : '1',
                letterSpacing: i18n.language === 'en' ? `calc(-30 / 1000 * 45px)` : `calc(-30 / 1000 * 40px)`,
                color: "#515D6A",
                [theme.breakpoints.down('sm')]: {
                    fontWeight: i18n.language === 'en' ? '400' : '400',
                    fontSize: i18n.language === 'en' ? '30px' : '28px',
                    lineHeight: i18n.language === 'en' ? `calc(40 / 30)` : `calc(40 / 28)`,
                    letterSpacing: i18n.language === 'en' ? `calc(-30 / 1000 * 30px)` : `calc(-30 / 1000 * 28px)`,
                },
            },
            h3: {
                fontFamily: getFontFamily(lang),
                fontWeight: i18n.language === 'en' ? '500' : '500',
                fontSize: i18n.language === 'en' ? '27px' : '25px',
                lineHeight: i18n.language === 'en' ? `calc(35 / 27)` : '1',
                letterSpacing: i18n.language === 'en' ? `calc(-30 / 1000 * 27px)` : `calc(-30 / 1000 * 25px)`,
                background: 'linear-gradient(120deg, #00C7D8 0%, #006490 100%)', // XDと異なるが、デザイナーから指定された値
                'WebkitBackgroundClip': 'text',
                'WebkitTextFillColor': 'transparent',
                display: 'inline-block',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    lineHeight: i18n.language === 'en' ? `calc(21 / 18)` : `calc(24 / 18)`,
                    letterSpacing: `calc(-30 / 1000 * 18px)`,
                },
            },
            body1: {
                ...theme.typography.body1,
                fontFamily: getFontFamily(lang),
                fontWeight: i18n.language === 'en' ? '400' : '400',
                fontSize: "20px",
                lineHeight: i18n.language === 'en' ? `calc(30 / 20)` : `calc(32 / 20)`,
                letterSpacing: i18n.language === 'en' ? '0px' : `calc(20 / 1000 * 20px)`,
                color: "#515D6A",
                [theme.breakpoints.down('sm')]: {
                    fontSize: '15px',
                    lineHeight: i18n.language === 'en' ? `calc(22 / 15)` : `calc(25 / 15)`,
                    letterSpacing: i18n.language === 'en' ? 0 : `calc(20 / 1000 * 15px)`,
                },
            },
            body2: {
                ...theme.typography.body2,
                fontFamily: getFontFamily(lang),
                fontStyle: 'normal',
                fontWeight: i18n.language === 'en' ? '400' : '500',
                fontSize: i18n.language === 'en' ? '16px' : '14px',
                lineHeight: i18n.language === 'en' ? `calc(25 / 16)` : `calc(25 / 14)`,
                letterSpacing: i18n.language === 'en' ? '0px' : `calc(20 / 1000 * 14px)`,
                color: '#515D6A',
                [theme.breakpoints.down('sm')]: {
                    fontWeight: i18n.language === 'en' ? '400' : '400',
                    fontSize: i18n.language === 'en' ? '11px' : '12px',
                    lineHeight: i18n.language === 'en' ? `calc(15 / 11)` : `calc(18 / 12)`,
                    letterSpacing: i18n.language === 'en' ? `calc(8 / 1000 * 11px)` : `calc(8 / 1000 * 12px)`,
                },
            },
        }
    });
    return walccareTheme
}
